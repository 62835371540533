export const uuidShort = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const uuidLong = () =>
  // eslint-disable-next-line prefer-template
  uuidShort() +
  uuidShort() +
  '-' +
  uuidShort() +
  '-' +
  uuidShort() +
  '-' +
  uuidShort() +
  '-' +
  uuidShort() +
  uuidShort() +
  uuidShort();

import React, { PropsWithChildren } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as S from './Chip.styles';
import { ChipProps } from '@/components/UI/Chip/Chip.types';
import { cleanupSeoUrl } from '@/utils/helpers';
import { KeywordsSource } from '@/components/SearchSuggestions/SearchSuggestions.types';

export const Chip: React.FC<PropsWithChildren<ChipProps>> = ({
  children,
  appearance = 'default',
  size = 'l',
  isRounded,
  href,
  onClick,
  ...props
}) => {
  const router = useRouter();

  const onLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!href) return;

    const url = new URL(href, window.location.origin);

    url.searchParams.set('keywords_source', KeywordsSource.Typewritten);
    router.push(`${url.pathname}?${url.searchParams.toString()}`);
  };

  return (
    <S.ChipBox
      {...props}
      onClick={href ? onClick || onLinkClick : onClick}
      {...(!!href && { as: Link, href: cleanupSeoUrl(href, true) })}
      $appearance={appearance}
      $size={size}
      $isRounded={isRounded}
    >
      {children}
    </S.ChipBox>
  );
};

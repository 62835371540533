import styled, { css } from 'styled-components';
import { Button } from '@/components/UI/Button/Button';
import { CustomLink } from '@/components/CustomLink/CustomLink';

export const TopRightActionsBox = styled.div`
  position: absolute;
  right: 15px;
`;

const WebViewTopActions = css`
  width: 100%;
  z-index: 9999;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => (theme.isWebView ? '5px 15px' : '20px 15px 5px')};
  background-color: ${({ theme }) => theme.palette.primary.background};
  position: ${({ theme }) => (theme.isWebView ? 'fixed' : 'relative')};
  z-index: 10;
  min-height: 28px;

  ${({ theme }) => theme.isWebView && WebViewTopActions}
`;

export const NewAdButton = styled(CustomLink)`
  display: flex;
`;

export const CloseWebviewButton = styled(Button)`
  padding-right: 0;
`;

import styled from 'styled-components';
import { removeScrollbar } from '@/styles/helpers';
import { waveAnimation } from '@/components/SkeletonLoader/SkeletonLoader.styles';

export const SubcategoriesScrollGridBox = styled.div`
  ${removeScrollbar()}

  display: flex;
  gap: 5px;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.palette.primary.background};
  padding-bottom: 15px;
  margin: 0 15px;
`;

export const SubcategoriesSkeletonLoaderBox = styled(
  SubcategoriesScrollGridBox
)`
  width: fit-content;
`;

export const SubcategoriesSkeletonLoader = styled.div`
  border-radius: 7px;
  width: 94px;
  height: 38.8px;
  animation: ${waveAnimation} 2s ease-in-out 0.5s infinite;
  background-color: ${({ theme }) => theme.palette.secondary.background};
`;

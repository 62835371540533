export type PropertyOption = {
  name: string;
  legacyResourceId: string;
  parentLegacyId: string;
  propertyLegacyId: string;
  childrenPropertyIds: string[];
  hasChildren: boolean;
};

export type PropertySetting = {
  from: number;
  step: number;
  to: number;
};

export enum PropertyType {
  CollectionProperty = 'CollectionProperty',
  BooleanProperty = 'BooleanProperty',
  RangeProperty = 'RangeProperty',
}

export type Property = {
  id: string;
  name: string;
  popularOptions: PropertyOption[];
  options: PropertyOption[];
  position: number;
  settings: PropertySetting;
  type: PropertyType;
  parentLegacyId: string | null;
};

export type AdBundle = {
  id: string;
  count: number;
  price: number;
};

export type Category = {
  children: Category[];
  count: number;
  hasAutogeneratedTitle: boolean;
  hints: string[];
  icon: string;
  id: string;
  metaDescription: string;
  isPhotoRequired: boolean;
  isPriceRequired: boolean;
  name: string;
  parent: Category | null;
  path: string;
  position: number;
  propertyOptions?: PropertyOption[];
  bundles: AdBundle[];
  warningMessage?: string;
  properties?: Property[];
  isFreeLimitExceededForUser: boolean;
  paidAdsBalance?: number;
};

export type CategoryBaseInfo = {
  id: string;
  name: string;
  path: string;
  icon: string;
  count: number;
  parent: CategoryBaseInfo | null;
};

export type GetCategoryResponse = {
  category: CategoryBaseInfo;
};

export type GetCategoryDetailsResponse = {
  category: Category;
};

export type GetCategoryAdsCountResponse = {
  category: Pick<Category, 'count'>;
};

export type GetCategoriesResponse = {
  categories: Category[];
};

export type CategoryProperties = Pick<Category, 'id' | 'properties'>;

export type GetCategoryPropertiesResponse = {
  category: CategoryProperties;
};

export type GetCategoryEntitiesResponse = {
  categoryEntities: (Category | PropertyOption)[];
};

export type GetRootCategoriesResponse = {
  categories: Category[];
};

export type GetChildPropertyOptionsResponse = {
  property: {
    popularOptions: PropertyOption[];
    options: PropertyOption[];
  };
};

import { Category } from '@/api/types/categories.types';

export const groupCategoriesByParentName = (categories: Category[]) =>
  categories.reduce((groupedCategories, category) => {
    const parentName = category.parent?.name;
    if (!parentName) return groupedCategories;
    const currentGroup = groupedCategories.get(parentName) ?? [];
    groupedCategories.set(
      parentName,
      currentGroup.map(({ id }) => id).includes(category.id)
        ? currentGroup
        : [...currentGroup, category]
    );
    return groupedCategories;
  }, new Map<string, Category[]>());

export function findSubcategoryByName(
  query: string,
  categories?: Category[]
): Category[] {
  if (!categories) return [];

  const matchingCategories: Category[] = [];

  function searchInCategory(category: Category) {
    if (category.name.toLowerCase().includes(query.toLowerCase())) {
      matchingCategories.push(category);
    }

    category.children?.forEach(searchInCategory);
  }

  categories.forEach(searchInCategory);

  return matchingCategories;
}

export function findAndGroupCategoriesByParentName(
  query: string,
  categories?: Category[]
) {
  const matchingCategories = findSubcategoryByName(query, categories);
  return groupCategoriesByParentName(matchingCategories);
}

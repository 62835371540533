import { useEffect, useState } from 'react';
import { useBannerStore } from '@/store/useBannerStore/useBannerStore';
import { BannerSource } from '@/store/useBannerStore/useBannerStore.types';

export function useAdManagerBannerVisible() {
  const [shouldShowAdManager, setShouldShowAdManager] = useState(false);
  const { setBannerTopSource, setBannerBottomSource } = useBannerStore();

  useEffect(() => {
    const checkBanners = () => {
      const topBanner = document.querySelector('.lotriver-top-banner');
      const firstBottomBanner = document.querySelector(
        '.lotriver-bottom-banner--in-list'
      );

      const isTopLoaded =
        !!topBanner?.clientHeight && topBanner.clientHeight > 0;
      const isFirstBottomLoaded =
        !!firstBottomBanner?.clientHeight && firstBottomBanner.clientHeight > 0;

      if (!isTopLoaded) {
        setShouldShowAdManager(true);
        setBannerTopSource(BannerSource.Admanager);
      }

      if (!isFirstBottomLoaded) {
        setBannerBottomSource(BannerSource.Admanager);
      }
    };

    setTimeout(checkBanners, 2500);
  }, [setBannerTopSource, setBannerBottomSource]);

  return shouldShowAdManager;
}

import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Drawer.styles';
import { CloseIcon } from '@/components/UI/Icons/Icons';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import {
  DrawerProps,
  DrawerPropsWithLeftBtn,
} from '@/components/Drawer/Drawer.types';
import {
  DrawerSearch,
  DrawerSearchRef,
} from '@/components/Drawer/DrawerSearch';
import { Portal } from '@/components/UI/Portal/Portal';
import NoSsr from '@/components/NoSsr/NoSsr';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';

export const Drawer: React.FC<
  PropsWithChildren<DrawerProps | DrawerPropsWithLeftBtn>
> = ({
  children,
  isOpen,
  close,
  title,
  subtitle,
  subtitleVariant,
  left,
  right,
  bottom,
  hasBottomMargin = true,
  hasBottomBorder = true,
  onSearch,
  searchPlaceholder,
  isInPortal,
  className,
  dataTestId,
  isHeadFixed = true,
  capitalizeTitle,
  isHeadHidden,
  isLoading,
}) => {
  useBodyOverflow(isOpen);

  const { t } = useTranslation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<DrawerSearchRef>(null);

  useEffect(() => {
    searchRef.current?.clear();
  }, [isOpen]);

  function renderDrawerContent() {
    return (
      <>
        {!isHeadHidden && (
          <S.DrawerItem
            $isHead
            $isLast={!subtitle && hasBottomBorder && !onSearch}
            $hasBottomMargin={hasBottomMargin && !onSearch}
            as="div"
          >
            <S.DrawerClose
              data-testid="drawer-close-button"
              $shape="square"
              $appearance="transparent"
              onClick={left ? left.onClick : close}
            >
              {left ? left.icon : <CloseIcon />}
            </S.DrawerClose>

            {typeof title === 'string' && (
              <S.DrawerSimpleTitle $capitalizeTitle={capitalizeTitle}>
                {title}
              </S.DrawerSimpleTitle>
            )}

            {typeof title !== 'string' && title?.iconSrc && title?.text && (
              <S.DrawerTitle $capitalizeTitle={capitalizeTitle}>
                <IconBox $src={title?.iconSrc} width={15} height={13} />
                {title?.text}
              </S.DrawerTitle>
            )}

            {right && (
              <S.RightAction
                appearance="primaryText"
                onClick={right.onClick}
                disabled={right.isDisabled}
              >
                {right?.title ?? t('common.reset')}
              </S.RightAction>
            )}
          </S.DrawerItem>
        )}
        {typeof subtitle === 'string' ? (
          <S.DrawerSubtitle $variant={subtitleVariant ?? '1'}>
            {subtitle}
          </S.DrawerSubtitle>
        ) : (
          subtitle
        )}
        {onSearch && (
          <DrawerSearch
            onSearch={onSearch}
            placeholder={searchPlaceholder}
            ref={searchRef}
            isLoading={isLoading}
          />
        )}
        <S.DrawerChildren $isHeadFixed={isHeadFixed} ref={drawerRef}>
          {children}
        </S.DrawerChildren>
      </>
    );
  }

  function renderDrawer() {
    return (
      <S.DrawerBox
        data-testid={dataTestId}
        $isOpen={isOpen}
        className={className}
      >
        {isHeadFixed ? (
          renderDrawerContent()
        ) : (
          <S.DrawerContentBox>{renderDrawerContent()}</S.DrawerContentBox>
        )}

        {bottom && <S.DrawerBottom>{bottom}</S.DrawerBottom>}
      </S.DrawerBox>
    );
  }

  if (isInPortal) {
    return (
      <Portal>
        <NoSsr>{renderDrawer()}</NoSsr>
      </Portal>
    );
  }

  return renderDrawer();
};

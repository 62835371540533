import React, { useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import * as S from './DrawerSearch.styles';
import { Input } from '@/components/UI/Input/Input';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';

interface Props {
  onSearch: (value: string) => void;
  placeholder?: string;
  isLoading?: boolean;
}

export interface DrawerSearchRef {
  clear: () => void;
}

type DrawerSearchFormFields = {
  name: string;
};

export const DrawerSearch = React.forwardRef<DrawerSearchRef, Props>(
  ({ onSearch, placeholder, isLoading }, drawerSearchRef) => {
    const { t } = useTranslation();
    const { control, reset, watch } = useForm<DrawerSearchFormFields>({
      defaultValues: { name: '' },
    });
    const { name } = watch();

    function clear() {
      reset({ name: '' });
    }

    useImperativeHandle(drawerSearchRef, () => ({ clear }), []);

    useEffect(() => {
      onSearch(name);
    }, [name]);

    return (
      <S.Form onSubmit={e => e.preventDefault()}>
        {isLoading ? (
          <SkeletonLoader
            dataTestId="drawer-search-skeleton-loader"
            borderRadius={7}
            height="40px"
          />
        ) : (
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                appearance="filledWithIcon"
                helperText={fieldState.error?.message}
                hasError={!!fieldState.error}
                clear={clear}
                placeholder={placeholder ?? t('common.search')}
              />
            )}
          />
        )}
      </S.Form>
    );
  }
);

DrawerSearch.displayName = 'DrawerSearch';

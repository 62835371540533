import { useEffect, useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAdDetails } from '@/views/Ads/useAdDetails';
import { AdStatus } from '@/types';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { ROUTES } from '@/constants/routes';
import { useNavigationStore } from '@/store/useNavigationStore';
import { useMobileDetect } from '@/hooks/useMobileDetect';

export const useNavigation = () => {
  const router = useRouter();
  const { adDetails } = useAdDetails();
  const customPush = useCustomPush();
  const { isIos } = useMobileDetect();
  const [referrer, canGoBack, setReferrer, setCanGoBack] = useNavigationStore(
    state => [
      state.referrer,
      state.canGoBack,
      state.setReferrer,
      state.setCanGoBack,
    ]
  );
  const [navigationType, setNavigationType] = useState<NavigationTimingType>(
    (
      window?.performance?.getEntriesByType(
        'navigation'
      )?.[0] as PerformanceNavigationTiming
    ).type
  );

  const { status: adStatus } = adDetails ?? {};
  const currentUrl = window.location.href;
  const storageInitialUrl = sessionStorage.getItem('initialUrl');

  const isPageReloaded = navigationType === 'reload';
  const isPopState = navigationType === 'back_forward';
  const isSameDomain = referrer.includes(window.location.hostname);
  const isAwaitingPaymentAdPage = adStatus === AdStatus.AwaitingPayment;
  const hasInitialUrl = !!storageInitialUrl;
  const isAtInitialPage = storageInitialUrl === currentUrl;
  const canGoBackValue =
    hasInitialUrl &&
    (isPageReloaded || isPopState ? !isAtInitialPage : isSameDomain);

  useLayoutEffect(() => {
    if (canGoBack === undefined) {
      setCanGoBack(canGoBackValue);
    }
  }, []);

  useEffect(() => {
    setCanGoBack(canGoBackValue);

    if (isIos) {
      document.body.style.opacity = '';
    }

    const handleRouteChange = (newPath: string) => {
      if (isIos && newPath !== router.asPath) {
        document.body.style.opacity = '0';
      }
      setReferrer(currentUrl);
      setNavigationType('navigate');
      handleChangeInitialUrl();
    };

    const handleChangeInitialUrl = () => {
      if (!storageInitialUrl) {
        sessionStorage.setItem('initialUrl', currentUrl);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('pagehide', handleChangeInitialUrl);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.removeEventListener('pagehide', handleChangeInitialUrl);
    };
  }, [router.asPath]);

  function goBack() {
    if (isAwaitingPaymentAdPage) {
      customPush(ROUTES.profileAds(AdStatus.Pending));
      return;
    }

    window.history.back();
  }

  return {
    canGoBack: !!canGoBack,
    goBack,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { RangeCollection } from '@/components/FiltersDrawer/FiltersDrawer.types';
import { Input } from '@/components/UI/Input/Input';
import { Property } from '@/api/types/categories.types';
import { getUpdatedCollection } from '@/components/FiltersDrawer/RangeOptions.helpers';
import { EMPTY_RESULTS } from '@/components/FiltersDrawer/RangeOptions.constants';
import { FieldType } from '@/components/FiltersDrawer/RangeOptions.types';
import * as S from '@/components/FiltersDrawer/FiltersDrawer.styles';

type Props = {
  property: Property;
  collection: RangeCollection;
  setCollection: React.Dispatch<React.SetStateAction<RangeCollection>>;
};

export const RangeOptions = ({
  property,
  collection,
  setCollection,
}: Props) => {
  const { t } = useTranslation();

  function updateCollection(value: string | null, type: FieldType) {
    const updatedCollection = getUpdatedCollection(
      value,
      type,
      collection[property.id]
    );

    setCollection(prev => ({
      ...prev,
      [property.id]: updatedCollection,
    }));
  }

  function handleChange(value: string, type: FieldType) {
    updateCollection(value, type);
  }

  function handleClear(type: FieldType) {
    updateCollection(null, type);
  }

  const [from, to] = collection[property.id] || EMPTY_RESULTS;

  return (
    <>
      <S.FieldTitle>{property.name}</S.FieldTitle>
      <S.TwoFieldsInRow>
        <Input
          value={from ?? ''}
          onChange={value => handleChange(value as string, 'from')}
          clear={() => handleClear('from')}
          type="text"
          inputMode="numeric"
          placeholder={t('common.from')}
          isNumberWithSpaces
        />
        <Input
          value={to ?? ''}
          onChange={value => handleChange(value as string, 'to')}
          clear={() => handleClear('to')}
          type="text"
          inputMode="numeric"
          placeholder={t('common.to')}
          isNumberWithSpaces
        />
      </S.TwoFieldsInRow>
    </>
  );
};

import styled, { css } from 'styled-components';
import { BadgeAppearance } from '@/components/UI/Badge/Badge.types';
import { Typography } from '@/components/UI/Typography/Typography.styles';

function getBadgeStyles(appearance: BadgeAppearance) {
  switch (appearance) {
    case 'white': {
      return css`
        background: ${({ theme }) => theme.palette.system.white};
        color: ${({ theme }) => theme.palette.brand.main};
      `;
    }
    default: {
      return css`
        background: ${({ theme }) => theme.palette.brand.main};
        color: ${({ theme }) => theme.palette.system.white};
      `;
    }
  }
}

export const Box = styled.div`
  position: relative;
`;

export const Value = styled(Typography.Caption)<{
  $appearance: BadgeAppearance;
}>`
  ${({ $appearance }) => getBadgeStyles($appearance)};
  z-index: 1;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 3px;
  right: 3px;
  height: 15px;
  width: 15px;
  aspect-ratio: 1;
  padding: 1px 4px;
  border-radius: 25px;
  overflow: hidden;
`;

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './SubcategoriesScrollGrid.styles';
import { useAllListOptions } from './SubcategoriesScrollGrid.hooks';
import { PropertyOption } from '@/api/types/categories.types';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import {
  createNewUrlWithPropertyOption,
  createSubcategoryLink,
} from '@/components/SubcategoriesScrollGrid/SubcategoriesScrollGrid.helpers';
import {
  CollectionSelectDrawer,
  CollectionSelectDrawerRef,
} from '@/components/FiltersDrawer/CollectionSelectDrawer';
import { useParseQuery } from '@/hooks/useParseQuery/useParseQuery';
import { MAX_CATEGORY_ENTITIES } from '@/components/SubcategoriesScrollGrid/SubcategoriesScrollGrid.constants';
import { useCategoryProperties } from '@/hooks/useCategoryProperties';
import { useUrlAndHashFromPath } from '@/hooks/useUrlAndHashFromPath';
import { useCurrentCategory } from '@/hooks/useCurrentCategory/useCurrentCategory';
import { useCategoryEntities } from '@/hooks/useCategoryEntities';
import { Chip } from '@/components/UI/Chip/Chip';
import { SearchResultsPageTestIds } from '@/__e2e__/testIds/search-results-page';

export const SubcategoriesScrollGrid = () => {
  const { t } = useTranslation();
  const customPush = useCustomPush();
  const { currentCategory, isSubcategories } = useCurrentCategory();
  const collectionSelectDrawerRef = useRef<CollectionSelectDrawerRef>(null);
  const {
    categoryId,
    propertyOptions: { collection: collectionPropertyOptions },
  } = useParseQuery();
  const { categoryProperties } = useCategoryProperties();
  const { queryString } = useUrlAndHashFromPath();
  const currentCategoryId = categoryId || currentCategory?.id;
  const { categoryEntities, loading } = useCategoryEntities();
  const hasParentPropertyId = collectionPropertyOptions.length;
  const isLevel2PropertySelected = collectionPropertyOptions.length > 1;
  const popularOptions =
    isSubcategories && categoryEntities.length >= MAX_CATEGORY_ENTITIES
      ? (categoryEntities.slice(0, 5) as PropertyOption[])
      : [];
  const list = popularOptions.length ? popularOptions : categoryEntities;
  const allListOptions = useAllListOptions();

  async function goToPropertyOption(propertyOption: PropertyOption) {
    const newUrl = createNewUrlWithPropertyOption(propertyOption);
    await customPush(newUrl);
  }

  function toggleDrawer() {
    collectionSelectDrawerRef.current?.toggle();
  }

  if (!currentCategoryId || loading) {
    return (
      <S.SubcategoriesSkeletonLoaderBox>
        {Array.from({ length: 5 }, (_, index) => (
          <S.SubcategoriesSkeletonLoader key={index} />
        ))}
      </S.SubcategoriesSkeletonLoaderBox>
    );
  }

  if (!categoryEntities.length || isLevel2PropertySelected) {
    return null;
  }

  return (
    <S.SubcategoriesScrollGridBox
      data-testid={SearchResultsPageTestIds.subcategoriesScrollGrid}
    >
      {list?.map(subcategory => {
        return (
          <Chip
            data-testid={SearchResultsPageTestIds.subcategoriesScrollGridItem}
            key={subcategory.name}
            href={createSubcategoryLink(subcategory, queryString)}
          >
            {subcategory.name}
          </Chip>
        );
      })}
      {!!popularOptions?.length && (
        <>
          <Chip
            onClick={toggleDrawer}
            data-testid={SearchResultsPageTestIds.subcategoriesScrollGridItem}
          >
            {t('common.all_list')}
          </Chip>
          <CollectionSelectDrawer
            options={allListOptions}
            allProperties={categoryProperties}
            popularOptions={!hasParentPropertyId ? popularOptions : []}
            ref={collectionSelectDrawerRef}
            propertyLegacyId={popularOptions[0]?.propertyLegacyId}
            onItemClick={option => {
              goToPropertyOption(option);
              toggleDrawer();
            }}
          />
        </>
      )}
    </S.SubcategoriesScrollGridBox>
  );
};

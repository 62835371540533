import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useBodyLockCountStore } from '@/store/useBodyLockCountStore';
import { uuidShort } from '@/utils/uuid-generators';

export const useBodyOverflow = (isOpen?: boolean) => {
  const { incrementLockCount, decrementLockCount } = useBodyLockCountStore();
  const savedScrollYRef = useRef(0);
  const instanceId = useRef(uuidShort());
  const router = useRouter();

  function onOpen() {
    const newLockCount = incrementLockCount(instanceId.current);
    if (newLockCount === 1) {
      savedScrollYRef.current = window.scrollY;
      document.documentElement.style.top = `-${savedScrollYRef.current}px`;
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.width = '100%';
    }
  }

  function onClose(shouldRestoreScroll = true) {
    const newLockCount = decrementLockCount(instanceId.current);
    if (newLockCount === 0) {
      document.documentElement.style.top = '';
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.position = '';
      document.documentElement.style.width = '';
      if (shouldRestoreScroll && savedScrollYRef.current) {
        window.scrollTo(0, savedScrollYRef.current);
      }
    }
  }

  useEffect(() => {
    const handleRouteChangeStart = () => {
      onClose(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      onOpen();
      return;
    }
    onClose();

    return () => {
      if (isOpen) {
        onClose();
      }
    };
  }, [isOpen]);

  return null;
};

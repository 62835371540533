export const CommonTestIds = {
  popup: {
    title: 'popup-title',
    description: 'popup-description',
    actionLeft: 'popup-action-left',
    actionRight: 'popup-action-right',
  },
  adsSection: {
    section: 'ads-section',
    title: 'ads-section-title',
    showMoreButton: 'ads-section-show-more-button',
  },
  adCards: {
    cardsBox: 'ad-cards-box',
    card: 'ad-card',
  },
  errorPage: {
    notFoundImage: 'error-page-not-found-image',
    unexpectedErrorImage: 'error-page-unexpected-error-image',
    title: 'error-page-title',
    subtitle: 'error-page-subtitle',
  },
  input: {
    input: 'input',
  },
  header: {
    backButton: 'header-back-button',
  },
};

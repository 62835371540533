import { create } from 'zustand';

interface NavigationStore {
  referrer: string;
  canGoBack: boolean | undefined;
  setReferrer: (value: string) => void;
  setCanGoBack: (value: boolean) => void;
}

export const useNavigationStore = create<NavigationStore>(set => ({
  referrer: typeof document !== 'undefined' ? document.referrer : '',
  setReferrer: value => set({ referrer: value }),
  canGoBack: undefined,
  setCanGoBack: value => set({ canGoBack: value }),
}));

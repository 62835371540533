import { Property, PropertyType } from '@/api/types/categories.types';
import {
  GroupedProperties,
  UpdateUrlParamsProps,
} from '@/components/FiltersDrawer/FiltersDrawer.types';
import { isBooleanString } from '@/utils/helpers';

const numericKeyPattern = /^p\[\d+]$/;
const arrayKeyPattern = /^(p|q)\[[^\]]+]\[]$/;

// TODO: Remove it when its no longer dependant on unit tests
export function removeKeysOnClear(
  searchParams: URLSearchParams,
  isRangeCollection = false
) {
  const keysToRemove = [];
  const pattern = isRangeCollection ? arrayKeyPattern : null;

  for (const key of searchParams.keys()) {
    if (key === 'q[price][]' || key === 'q[region_id]') {
      continue;
    }
    if (numericKeyPattern.test(key) || (pattern && pattern.test(key))) {
      keysToRemove.push(key);
    } else {
      const value = searchParams.get(key);
      if (value === 'true' || value === 'false') {
        keysToRemove.push(key);
      }
    }
  }

  keysToRemove.forEach(key => searchParams.delete(key));
}

function modifyUrlPath(urlObj: URL, hasCategory: boolean) {
  try {
    const { pathname } = urlObj;

    if (!hasCategory) {
      // eslint-disable-next-line no-param-reassign
      urlObj.pathname = pathname.replace(/^\/elanlar(\/.*)?$/, '/elanlar');
    }
    return urlObj;
  } catch (error) {
    return urlObj;
  }
}

function isRestrictedParam(key: string) {
  return ['q[price][]', 'q[region_id]'].includes(key);
}

export function updateURLParameters({
  url,
  params,
  booleanCollection,
  rangeCollection,
  collection,
  hasCategory = false,
}: UpdateUrlParamsProps): string {
  const updatedURL = new URL(url);
  const searchParams = new URLSearchParams(updatedURL.search);

  ['button', 'mobile_site', 'user_id'].forEach(param =>
    searchParams.delete(param)
  );

  for (const key in params) {
    if (params[key] !== undefined) {
      if (Array.isArray(params[key])) {
        searchParams.delete(key);
        (params[key] as string[]).forEach(value =>
          searchParams.append(key, value)
        );
      } else {
        searchParams.set(key, params[key] as string);
      }
    }
  }

  if (collection) {
    for (const key of [...searchParams.keys()]) {
      const value = searchParams.get(key);

      if (isRestrictedParam(key)) continue;
      if (numericKeyPattern.test(key) && Number(value)) {
        searchParams.delete(key);
      }
    }

    if (Object.entries(collection).length > 0) {
      for (const [key, value] of Object.entries(collection)) {
        if (value !== undefined) {
          searchParams.set(`p[${key}]`, value);
        } else {
          searchParams.delete(`p[${key}]`);
        }
      }
    }
  }

  if (booleanCollection) {
    for (const key of [...searchParams.keys()]) {
      const value = searchParams.get(key);

      if (isRestrictedParam(key)) continue;
      if (numericKeyPattern.test(key) && isBooleanString(value)) {
        searchParams.delete(key);
      }
    }

    if (Object.entries(booleanCollection).length > 0) {
      for (const [key, value] of Object.entries(booleanCollection)) {
        if (value !== undefined) {
          searchParams.set(`p[${key}]`, String(value));
        } else {
          searchParams.delete(`p[${key}]`);
        }
      }
    }
  }

  if (rangeCollection) {
    for (const key of [...searchParams.keys()]) {
      if (isRestrictedParam(key)) continue;
      if (arrayKeyPattern.test(key)) {
        searchParams.delete(key);
      }
    }

    if (Object.entries(rangeCollection).length > 0) {
      for (const [key, values] of Object.entries(rangeCollection)) {
        const queryKey = `p[${key}][]`;
        searchParams.delete(queryKey);
        if (Array.isArray(values)) {
          values.forEach(value => searchParams.append(queryKey, String(value)));
        }
      }
    }
  }

  updatedURL.search = searchParams.toString();
  return modifyUrlPath(updatedURL, hasCategory).toString();
}

export function replaceUrlPath(baseUrl: string, newPath: string) {
  const url = new URL(baseUrl);
  url.pathname = newPath;
  return url.toString();
}

export function separateBooleanProperties(properties?: Property[]) {
  const initialGroupedProperties = {
    booleanProperties: new Set<Property>(),
    restProperties: new Set<Property>(),
  };

  const groupedProperties =
    properties?.reduce<GroupedProperties>((acc, p) => {
      switch (p.type) {
        case PropertyType.BooleanProperty:
          acc.booleanProperties.add(p);
          break;

        case PropertyType.CollectionProperty:
        case PropertyType.RangeProperty:
          acc.restProperties.add(p);
          break;
        default:
          break;
      }
      return acc;
    }, initialGroupedProperties) ?? initialGroupedProperties;

  return {
    booleanProperties: Array.from(groupedProperties.booleanProperties),
    restProperties: Array.from(groupedProperties.restProperties),
  };
}

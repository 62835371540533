import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';
import { handleSaveScrollPositionData } from '@/components/ScrollSaver/ScrollSaver.helpers';
import { useSaveAdsHandlersStore } from '@/store/useSaveAdsHandlersStore';
import { PAGE_WITH_ADS, QUERY } from '@/constants/common';

export function useSaveScrollPositionActions() {
  const router = useRouter();
  const handleSaveLastAds = useSaveAdsHandlersStore(
    state => state.handleSaveLastAds
  );

  function getCurrentPage(): PAGE_WITH_ADS | null {
    if (router.asPath === ROUTES.home) return PAGE_WITH_ADS.HOME;
    if (router.query[QUERY.user_id]) return PAGE_WITH_ADS.USER_ADS;
    if (router.asPath.includes(ROUTES.ad)) return PAGE_WITH_ADS.SEARCH_RESULTS;

    return null;
  }

  function saveScrollPositionActions() {
    const currentPage = getCurrentPage();

    if (currentPage) {
      handleSaveLastAds?.();
      handleSaveScrollPositionData(currentPage);
    }
  }

  return saveScrollPositionActions;
}

import { ParsedUrlQuery } from 'node:querystring';
import {
  BooleanPropertyOption,
  CollectionPropertyOption,
  Filter,
  RangePropertyOption,
} from './useParseQuery.types';
import {
  BooleanCollection,
  Collection,
  RangeCollection,
} from '@/components/FiltersDrawer/FiltersDrawer.types';
import { AdOrder } from '@/api/types/ads.types';
import { replaceSpaces } from '@/utils/helpers';
import { GetRegionsResponse } from '@/api/types/regions.types';
import { QUERY } from '@/constants/common';

export function returnFirstIfArray<T>(arr: T | T[]): T {
  return Array.isArray(arr) ? arr[0] : arr;
}

export function mapBooleanPropertyOptionsToBooleanCollection(
  options: BooleanPropertyOption[]
) {
  return options.reduce<BooleanCollection>((a, c) => {
    return { ...a, [c.legacyId]: c.value };
  }, {});
}

export function mapRangePropertyOptionsToRangeCollection(
  options: RangePropertyOption[]
) {
  return options.reduce<RangeCollection>((acc, curr) => {
    return {
      ...acc,
      [curr.legacyId]: [curr.valueFrom, curr.valueTo],
    };
  }, {});
}

export function mapCollectionPropertyOptionsToCollection(
  options: CollectionPropertyOption[]
) {
  return options.reduce<Collection>(
    (acc, { legacyId, value }) => ({ ...acc, [legacyId]: value }),
    {}
  );
}

export function mapQueryOrderToAdOrder(queryOrder?: string) {
  switch (queryOrder) {
    case 'price_asc':
      return AdOrder.PriceAsc;
    case 'price_desc':
      return AdOrder.PriceDesc;
    default:
      return undefined;
  }
}

export function parseBooleanValue(value?: string | string[]) {
  if (!value) return false;
  return value[0] === 'true' || value === 'true';
}

export function parseRangeValue(value?: string | string[]) {
  if (!Array.isArray(value) || value.length !== 2) {
    return { valueFrom: null, valueTo: null };
  }

  return {
    valueFrom: Number(replaceSpaces(value[0])) || null,
    valueTo: Number(replaceSpaces(value[1])) || null,
  };
}

export function parseCollectionValue(value?: string | string[]) {
  return value ? returnFirstIfArray(value) : null;
}

export function handleBooleanValues(
  booleanPropertyOptions: BooleanPropertyOption[],
  legacyId: number,
  value?: string | string[]
) {
  booleanPropertyOptions.push({
    legacyId,
    value: parseBooleanValue(value),
  });
}

export function handleCollectionValues(
  collectionPropertyOptions: CollectionPropertyOption[],
  legacyId: number,
  value?: string | string[]
) {
  const collectionValue = parseCollectionValue(value);
  if (collectionValue) {
    collectionPropertyOptions.push({
      legacyId,
      value: collectionValue,
    });
  }
}

export function handleRangeValues(
  rangePropertyOptions: RangePropertyOption[],
  legacyId: number,
  value?: string | string[]
) {
  rangePropertyOptions.push({
    legacyId,
    ...parseRangeValue(value),
  });
}

function isBooleanValue(value?: string | string[]) {
  return (
    value &&
    (value[0] === 'true' ||
      value[0] === 'false' ||
      value === 'true' ||
      value === 'false')
  );
}

function isRangeValue(value?: string | string[]) {
  return Array.isArray(value) && value.length === 2;
}

export function extractProperties(query: ParsedUrlQuery) {
  const collectionPropertyOptions: CollectionPropertyOption[] = [];
  const booleanPropertyOptions: BooleanPropertyOption[] = [];
  const rangePropertyOptions: RangePropertyOption[] = [];

  for (const key in query) {
    if (key.startsWith('p[') && key.endsWith(']')) {
      const legacyId = parseInt(key.slice(2, -1), 10);
      const value = query[key];

      if (isBooleanValue(value)) {
        handleBooleanValues(booleanPropertyOptions, legacyId, value);
      } else if (isRangeValue(value)) {
        handleRangeValues(rangePropertyOptions, legacyId, value);
      } else if (value) {
        handleCollectionValues(collectionPropertyOptions, legacyId, value);
      }
    }
  }

  return {
    collectionPropertyOptions,
    booleanPropertyOptions,
    rangePropertyOptions,
  };
}

export function parseQuery(
  query: ParsedUrlQuery,
  regionsData?: GetRegionsResponse | null
): Filter {
  const regionId =
    regionsData?.regions.find(
      el =>
        Number(el.legacyResourceId) ===
        Number(query[`${QUERY.q}[${QUERY.region_id}]`])
    )?.id || null;
  const regionLegacyId =
    returnFirstIfArray(query[`${QUERY.q}[${QUERY.region_id}]`]) || null;

  const keywords = query[`${QUERY.q}[${QUERY.keywords}]`];
  const keywordsFinal = returnFirstIfArray(keywords);
  const categoryId = query[QUERY.categoryId];
  const categoryIdFinal = returnFirstIfArray(categoryId);
  const userId = query[QUERY.user_id];
  const userIdFinal = returnFirstIfArray(userId);

  const priceArr = query[`${QUERY.q}[${QUERY.price}][]`];
  const priceFrom = priceArr ? priceArr[0] : null;
  const priceTo = priceArr ? priceArr[1] : null;

  const allCollections = extractProperties(query);

  const [finalPriceFrom, finalPriceTo] = [priceFrom, priceTo].map(el => {
    return el ? Number(replaceSpaces(el)) : null;
  });

  return {
    keywords: keywordsFinal,
    categoryId: categoryIdFinal,
    price: {
      from: finalPriceFrom,
      to: finalPriceTo,
    },
    regionId,
    regionLegacyId,
    propertyOptions: {
      collection: allCollections.collectionPropertyOptions,
      boolean: allCollections.booleanPropertyOptions,
      range: allCollections.rangePropertyOptions,
    },
    order: mapQueryOrderToAdOrder(returnFirstIfArray(query.order)),
    userLegacyId: userIdFinal,
  };
}

import { create } from 'zustand';

interface BodyLockCountStore {
  lockKeys: Set<string>;
  incrementLockCount: (key: string) => number;
  decrementLockCount: (key: string) => number;
}

export const useBodyLockCountStore = create<BodyLockCountStore>((set, get) => ({
  lockKeys: new Set(),

  incrementLockCount: key => {
    const newLockKeys = new Set(get().lockKeys);
    newLockKeys.add(key);
    set({ lockKeys: newLockKeys });
    return newLockKeys.size;
  },

  decrementLockCount: key => {
    const newLockKeys = new Set(get().lockKeys);
    newLockKeys.delete(key);
    set({ lockKeys: newLockKeys });
    return newLockKeys.size;
  },
}));

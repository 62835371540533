import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import {
  CATEGORY_SLUG_INDEX,
  SUBCATEGORY_SLUG_INDEX,
} from './useCurrentCategory.constants';
import { getCurrentCategoryPath } from './useCurrentCategory.helpers';
import {
  CategoryBaseInfo,
  GetCategoryResponse,
} from '@/api/types/categories.types';
import { GET_CATEGORY } from '@/api/queries/categories.queries';
import { useParseQuery } from '@/hooks/useParseQuery/useParseQuery';

type UseGetCurrentCategoryReturn = {
  currentCategory?: CategoryBaseInfo;
  isVipAds: boolean;
  isUserAds: boolean;
  isAllCategories: boolean;
  isFirstLevel: boolean;
  isSubcategories: boolean;
};

export function useCurrentCategory(): UseGetCurrentCategoryReturn {
  const {
    query: { slug },
    asPath,
  } = useRouter();
  const path = getCurrentCategoryPath(slug);
  const skipRegions = asPath.includes(`${path}/`);
  const { userLegacyId } = useParseQuery(skipRegions);
  const isVipAds = slug?.[0] === 'vip';
  const isUserAds = !!userLegacyId;
  const isAllCategories = !slug;
  const isFirstLevel = slug?.length === CATEGORY_SLUG_INDEX + 1;
  const isSubcategories = slug?.length === SUBCATEGORY_SLUG_INDEX + 1;

  const { data } = useQuery<GetCategoryResponse>(GET_CATEGORY, {
    variables: { path },
  });

  const currentCategory = data?.category;
  return {
    currentCategory,
    isVipAds,
    isUserAds,
    isAllCategories,
    isFirstLevel,
    isSubcategories,
  };
}

import React from 'react';
import * as S from './CatalogueListItem.styles';
import { Category } from '@/api/types/categories.types';
import {
  ALL_CATEGORIES_ID,
  ALL_SHOPS_ID,
} from '@/components/CatalogueScrollGrid/CatalogueScrollGrid.constants';
import { getImageSide } from '@/components/CatalogueListItem/CatalogueListItem.helpers';
import { CustomLink } from '@/components/CustomLink/CustomLink';

interface CatalogueListItemProps {
  item: Category;
  onClick?: () => void;
  isHorizontalView?: boolean;
  isStoresIcon?: boolean;
  isLoading?: boolean;
}

export const CatalogueListItem: React.FC<CatalogueListItemProps> = ({
  item,
  onClick,
  isHorizontalView,
  isStoresIcon,
  isLoading = false,
  ...props
}) => {
  const isAllCategoriesItem = item.id === ALL_CATEGORIES_ID;
  const isDynamicCategory =
    item.id !== ALL_CATEGORIES_ID && item.id !== ALL_SHOPS_ID;

  function handleClick(event?: React.MouseEvent) {
    if (isLoading || !item.id) return;

    if (isDynamicCategory) {
      event?.preventDefault();
    }

    onClick?.();
  }

  function getTexts() {
    if (isHorizontalView) {
      return (
        <S.GridItemTitle $variant="3" suppressHydrationWarning>
          {item.name}
        </S.GridItemTitle>
      );
    }

    return isLoading ? (
      <>
        <S.TextSkeletonLoader width="100px" height="18px" />
        <S.TextSkeletonLoader height="14.4px" />
      </>
    ) : (
      <>
        <S.ItemTitle $variant="1">{item.name}</S.ItemTitle>
        <S.ItemDescription>{item.metaDescription}</S.ItemDescription>
      </>
    );
  }

  return (
    <>
      <S.Box
        {...props}
        data-testid="catalogue-list-item"
        onClick={handleClick}
        $isHorizontalView={isHorizontalView}
        suppressHydrationWarning
        {...(!isAllCategoriesItem && {
          as: CustomLink,
          href: `/${item.path.replace(/^\//, '')}`,
          dataTestId: 'catalogue-list-item',
        })}
      >
        {isLoading ? (
          <S.ImageSkeletonLoader
            dataTestId="catalogue-list-item-image-skeleton-loader"
            $isHorizontalView={isHorizontalView}
          />
        ) : (
          <S.ImageBox $isHorizontalView={isHorizontalView}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={isDynamicCategory ? item.icon : `/images/${item.icon}`}
              alt={item.name}
              width={getImageSide(isAllCategoriesItem, isStoresIcon)}
              height={getImageSide(isAllCategoriesItem, isStoresIcon)}
              suppressHydrationWarning
            />
          </S.ImageBox>
        )}
        <S.Texts
          {...(isLoading &&
            !isHorizontalView && {
              'data-testid': 'catalogue-list-item-texts-skeleton-loader',
            })}
        >
          {getTexts()}
        </S.Texts>
      </S.Box>
    </>
  );
};

import { useRouter } from 'next/router';
import {
  EDIT_AD_PAGE_SLUG_INDEX,
  SINGLE_AD_PAGE_SLUGS_COUNT,
} from '@/hooks/useAdPageType/useAdPageType.constants';

export function useAdPageType() {
  const router = useRouter();
  const isSinglePage = router.query.slug?.length === SINGLE_AD_PAGE_SLUGS_COUNT;
  const isEditPage = router.query.slug?.[EDIT_AD_PAGE_SLUG_INDEX] === 'edit';

  return { isSinglePage, isEditPage };
}

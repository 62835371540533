import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { SelectAngle } from '@/components/UI/Select/Select.styles';
import * as S from '@/components/SearchSuggestions/SearchSuggestions.styles';
import { cutSentenceFor2Parts } from '@/utils/helpers';
import { ROUTES } from '@/constants/routes';
import { GET_SEARCH_SUGGESTIONS } from '@/api/queries/searchSuggestions.queries';
import {
  GetSearchSuggestionResponse,
  Properties,
  SearchSuggestion,
} from '@/api/types/searchSuggestions.types';
import { useSearch } from '@/hooks/useSearch';
import { Portal } from '@/components/UI/Portal/Portal';
import { usePageTopControlsStore } from '@/store/usePageTopControlsStore';
import { useDebounce } from '@/hooks/useDebounce';
import {
  KeywordsSource,
  Props,
} from '@/components/SearchSuggestions/SearchSuggestions.types';
import { useSearchStore } from '@/store/useSearchStore';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { useScrollVisibility } from '@/hooks/useScrollVisibility';

export const SearchSuggestions = ({
  searchQuery,
  setSearchQuery,
  isInteractionHappened,
  setIsInteractionHappened,
  scrollToInput,
  offsetTop,
}: Props) => {
  const router = useRouter();
  const search = useSearch();
  const { isTopOfPage } = useScrollVisibility();
  const debouncedSearchQuery = useDebounce(searchQuery.trim());
  const [results, setResults] = useState<SearchSuggestion[]>([]);
  const [currentSuggestion, setCurrentSuggestion] =
    useState<SearchSuggestion | null>(null);
  const [isSuggestionsOpened, setIsSuggestionsOpened] = useSearchStore(
    state => [state.isSuggestionsOpened, state.setIsSuggestionsOpened]
  );
  const [setTopControlsCanBeFixed, topControlsPosition] =
    usePageTopControlsStore(state => [
      state.setCanBeFixed,
      state.topControlsPosition,
    ]);
  const isTopControlsFixed = topControlsPosition === 'fixed';
  const isHome = router.pathname === ROUTES.home;

  function onSuggestionClick(suggestion: SearchSuggestion) {
    setIsSuggestionsOpened(false);
    setSearchQuery(suggestion.value);
    setCurrentSuggestion(suggestion);
    scrollToInput();
    localStorage.setItem(STORAGE_KEYS.NEED_TO_RESTORE_SCROLL_POSITION, 'false');
  }

  useQuery<GetSearchSuggestionResponse>(GET_SEARCH_SUGGESTIONS, {
    skip:
      !debouncedSearchQuery || (!!debouncedSearchQuery && !!currentSuggestion),
    fetchPolicy: 'no-cache',
    variables: { keywords: debouncedSearchQuery },
    onCompleted: data => {
      const formattedSuggestions = data.searchSuggestions.map(item => {
        const propertiesJSON = item.properties?.replace?.(/=>/g, ':') ?? '{}';

        return {
          ...item,
          properties: JSON.parse(propertiesJSON) as Properties,
        };
      });

      setResults(formattedSuggestions);
    },
  });

  useEffect(() => {
    setIsSuggestionsOpened(
      currentSuggestion
        ? false
        : !!results.length && !!searchQuery && isInteractionHappened
    );
  }, [results.length, searchQuery, isInteractionHappened]);

  useEffect(() => {
    setTopControlsCanBeFixed(currentSuggestion ? false : !isSuggestionsOpened);
    if (!isSuggestionsOpened && !searchQuery) {
      setResults([]);
    }
  }, [isSuggestionsOpened, searchQuery]);

  useEffect(() => {
    if ((isTopOfPage || isTopControlsFixed) && !!currentSuggestion) {
      const params = {
        keywordsSource: KeywordsSource.Suggestion,
        ...(currentSuggestion.properties && {
          properties: currentSuggestion.properties,
          categoryPath: currentSuggestion.categoryPath,
        }),
      };

      search(searchQuery, params).then(() => {
        setCurrentSuggestion(null);
      });
    }
  }, [isTopOfPage, currentSuggestion, isTopControlsFixed]);

  return (
    <Portal>
      <S.OverlayCustom
        $isOpen={isSuggestionsOpened}
        $isHome={isHome}
        data-testid="overlay"
        onClick={() => setIsInteractionHappened(false)}
      />
      <S.Box
        data-testid="search-suggestions"
        $isOpen={isSuggestionsOpened}
        $isHome={isHome}
        $isTopControlsFixed={isTopControlsFixed}
        $offsetTop={offsetTop}
      >
        {results.map((suggestion, i, arr) => {
          const [entered, rest] = cutSentenceFor2Parts(
            suggestion.value,
            debouncedSearchQuery
          );

          return (
            <S.Item
              key={i}
              $isFirst={!i}
              $isLast={i === arr.length - 1}
              onClick={() => onSuggestionClick(suggestion)}
              data-testid={`search-suggestion-${i}`}
            >
              {suggestion.subvalue ? (
                <S.CategoryItem>
                  <S.Text data-testid="search-suggestion-text">
                    {entered}

                    <b>{rest}</b>
                  </S.Text>
                  <S.CategoryText data-testid="search-suggestion-category">
                    {suggestion.subvalue}
                  </S.CategoryText>
                </S.CategoryItem>
              ) : (
                <S.Text>
                  {entered}

                  <b>{rest}</b>
                </S.Text>
              )}

              <SelectAngle>
                <AngleBackIcon />
              </SelectAngle>
            </S.Item>
          );
        })}
      </S.Box>
    </Portal>
  );
};

import { Category, PropertyOption } from '@/api/types/categories.types';

export function createNewUrlWithPropertyOption(
  newPropertyOption: PropertyOption
) {
  const url = new URL(window.location.href);

  const params = new URLSearchParams(url.search);

  const { legacyResourceId, propertyLegacyId } = newPropertyOption;
  params.set(`p[${propertyLegacyId}]`, legacyResourceId.toString());

  url.search = params.toString();

  return url.pathname + url.search;
}

export function createSubcategoryLink(
  categoryEntity: Category | PropertyOption,
  queryString: string
) {
  if ('path' in categoryEntity && categoryEntity.path) {
    return queryString
      ? `/${categoryEntity.path}?${queryString}`
      : `/${categoryEntity.path}`;
  }

  if ('propertyLegacyId' in categoryEntity) {
    return createNewUrlWithPropertyOption(categoryEntity);
  }

  return '';
}

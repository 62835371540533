import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { GetAdDetailsResponse } from '@/api/types/ads.types';
import { GET_AD_DETAILS } from '@/api/queries/ads.queries';
import { useAdPageType } from '@/hooks/useAdPageType/useAdPageType';
import { usePlatformSource } from '@/hooks/usePlatformSource';

export function useAdDetails() {
  const router = useRouter();
  const { isSinglePage } = useAdPageType();
  const source = usePlatformSource();

  const legacyId = router.query.slug?.[2];

  const { data, loading, refetch, previousData } =
    useQuery<GetAdDetailsResponse>(GET_AD_DETAILS, {
      variables: {
        legacyId,
        source,
      },
      errorPolicy: 'all',
      skip: !legacyId || !isSinglePage,
    });

  return { adDetails: data?.adDetails, loading, refetch, previousData };
}

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCustomPush } from '../CustomLink/CustomLink.hooks';
import * as S from './CatalogueScrollGrid.styles';
import { CatalogueListItem } from '@/components/CatalogueListItem/CatalogueListItem';
import { CatalogueDrawer } from '@/components/CatalogueDrawer/CatalogueDrawer';
import {
  Category,
  GetCategoryEntitiesResponse,
  GetRootCategoriesResponse,
} from '@/api/types/categories.types';
import {
  ALL_CATEGORIES_ID,
  ALL_SHOPS_ID,
  STORAGE_KEY_REMOVING_DELAY,
} from '@/components/CatalogueScrollGrid/CatalogueScrollGrid.constants';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import {
  GET_CATEGORY_ENTITIES,
  GET_ROOT_CATEGORIES,
} from '@/api/queries/categories.queries';
import { ROUTES } from '@/constants/routes';
import { useParseQuery } from '@/hooks/useParseQuery/useParseQuery';
import { useUrlAndHashFromPath } from '@/hooks/useUrlAndHashFromPath';
import { categoriesMock } from '@/__e2e__/mock/categoriesMock';

export const CatalogueScrollGrid = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const router = useRouter();
  const customPush = useCustomPush();
  const { queryString } = useUrlAndHashFromPath();
  const isHomePage = router.asPath === ROUTES.home;
  const { keywords, price, regionId, propertyOptions, userLegacyId } =
    useParseQuery(isHomePage);

  const {
    data: { categoryEntities = [] } = {},
    loading: isCategoryEntitiesLoading,
  } = useQuery<GetCategoryEntitiesResponse>(GET_CATEGORY_ENTITIES, {
    variables: {
      keywords,
      filters: {
        price,
        regionId,
        propertyOptions,
        userLegacyId,
      },
    },
    skip: isHomePage,
  });

  const {
    data: { categories: rootCategories = [] } = {},
    loading: isRootCategoriesLoading,
  } = useQuery<GetRootCategoriesResponse>(GET_ROOT_CATEGORIES, {
    skip: !isHomePage,
  });

  const categories = isHomePage ? rootCategories : categoryEntities;
  const isLoading = isCategoryEntitiesLoading || isRootCategoriesLoading;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const catalogueScrollGridRef = useRef<HTMLDivElement>(null);

  function toggleDrawer() {
    setIsDrawerOpen(prev => !prev);
  }

  function saveScrollLeftToLS() {
    if (catalogueScrollGridRef.current?.scrollLeft) {
      localStorage.setItem(
        STORAGE_KEYS.CATALOGUE_SCROLL_LEFT,
        String(catalogueScrollGridRef.current.scrollLeft)
      );
    }
  }

  function restoreScrollLeftFromLS() {
    const scrollLeft =
      Number(localStorage.getItem(STORAGE_KEYS.CATALOGUE_SCROLL_LEFT)) || 0;

    if (catalogueScrollGridRef.current && scrollLeft) {
      catalogueScrollGridRef.current.scrollLeft = scrollLeft;

      setTimeout(() => {
        localStorage.removeItem(STORAGE_KEYS.CATALOGUE_SCROLL_LEFT);
      }, STORAGE_KEY_REMOVING_DELAY);
    }
  }

  function goWithSavingDataToLS(path = '') {
    customPush(queryString ? `${path}?${queryString}` : path);
    saveScrollLeftToLS();
  }

  useEffect(() => {
    restoreScrollLeftFromLS();
  }, []);

  const finalCategories = (
    isLoading ? categoriesMock(language).categories : categories
  ) as Category[];

  return (
    <>
      <S.Box data-testid="catalogue-scroll-grid" ref={catalogueScrollGridRef}>
        {isHomePage && (
          <CatalogueListItem
            item={
              {
                id: ALL_CATEGORIES_ID,
                icon: 'all-categories.png',
                name: t('catalogue.catalogue'),
              } as Category
            }
            onClick={toggleDrawer}
            isHorizontalView
            isLoading={isLoading}
          />
        )}

        {finalCategories.map(item => (
          <CatalogueListItem
            key={item.id}
            item={item}
            onClick={() => goWithSavingDataToLS(`/${item.path}`)}
            isHorizontalView
            isLoading={isLoading}
          />
        ))}

        {isHomePage && (
          <CatalogueListItem
            data-stat="header-category-shop"
            item={
              {
                id: ALL_SHOPS_ID,
                icon: 'shop.png',
                name: t('catalogue.stores'),
                path: ROUTES.shops,
              } as Category
            }
            isHorizontalView
            isLoading={isLoading}
            isStoresIcon
            onClick={() => goWithSavingDataToLS(`/${ROUTES.shops}`)}
          />
        )}
      </S.Box>

      <CatalogueDrawer
        isOpen={isDrawerOpen}
        close={toggleDrawer}
        hasSwitchCheckbox={false}
      />
    </>
  );
};

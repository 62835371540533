import React, { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { Button } from '@/components/UI/Button/Button';
import { AngleBackIcon, PlusIcon } from '@/components/UI/Icons/Icons';
import { BurgerNav } from '@/components/BurgerNav/BurgerNav';
import { LogoLink } from '@/components/LogoLink/LogoLink';
import { ROUTES } from '@/constants/routes';
import * as S from '@/components/Header/Header.styles';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';
import { CommonTestIds } from '@/__e2e__/testIds/common';

interface HeaderProps {
  title?: string;
  hasBurger?: boolean;
  leftAction?: React.ReactNode;
  hasLogoLink?: boolean;
  hasAddNew?: boolean;
  hasDivider?: boolean;
  isBackRequired?: boolean;
  hasLeadingAction?: boolean;
  hasWhiteBg?: boolean;
  notificationContainer?: React.ReactNode;
  leadingIcon?: React.ReactNode;
  canGoBack: boolean;
  goBack: () => void;
}

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({
  children,
  title,
  hasBurger,
  leftAction,
  hasLogoLink,
  hasAddNew,
  hasDivider = true,
  hasLeadingAction = true,
  hasWhiteBg = true,
  isBackRequired,
  notificationContainer,
  leadingIcon,
  canGoBack,
  goBack,
}) => {
  const theme = useTheme();

  function getTitle() {
    return title ? (
      <S.HeaderTitle $variant="3">{title}</S.HeaderTitle>
    ) : (
      <SkeletonLoader width="115px" />
    );
  }

  return (
    <>
      {notificationContainer}
      <S.HeaderBox
        $hasDivider={hasDivider}
        $hasCustomHeader={!!children}
        $hasLeadingAction={hasLeadingAction}
        $hasWhiteBg={hasWhiteBg}
      >
        {hasLeadingAction &&
          (isBackRequired || (canGoBack && !hasBurger) ? (
            <Button
              appearance="transparent"
              hasPadding={false}
              onClick={goBack}
              data-testid={CommonTestIds.header.backButton}
            >
              {leadingIcon || (
                <AngleBackIcon fill={theme.palette.primary.labels} />
              )}
            </Button>
          ) : (
            <BurgerNav />
          ))}

        {children || (
          <>
            {hasLogoLink ? <LogoLink /> : getTitle()}

            {hasAddNew ? (
              <S.NewAdLink href={ROUTES.newAd}>
                <PlusIcon />
              </S.NewAdLink>
            ) : (
              leftAction
            )}
          </>
        )}
      </S.HeaderBox>
    </>
  );
};

import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Filter } from './useParseQuery.types';
import { parseQuery } from './useParseQuery.helpers';
import { GetRegionsResponse } from '@/api/types/regions.types';
import { GET_REGIONS } from '@/api/queries/regions.queries';

export function useParseQuery(skipRegions = false): Filter {
  const { query } = useRouter();

  const { data: regions } = useQuery<GetRegionsResponse>(GET_REGIONS, {
    skip: skipRegions,
  });

  return useMemo(() => parseQuery(query, regions), [query, regions]);
}

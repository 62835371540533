import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  fragment BaseCategoryFields on Category {
    id
    name
    count
    path
    icon
  }

  query GetCategories {
    categories {
      ...BaseCategoryFields
      metaDescription
      propertyOptions {
        name
        legacyResourceId
        parentLegacyId
        propertyLegacyId
      }
      parent {
        ...BaseCategoryFields
      }
      children {
        id
        name
        path
        count
        parent {
          ...BaseCategoryFields
        }
      }
    }
  }
`;

export const GET_ROOT_CATEGORIES = gql`
  query GetRootCategories {
    categories {
      id
      name
      path
      icon
    }
  }
`;

export const GET_CATEGORY_PROPERTIES = gql`
  query CategoryProperties($path: String!) {
    category(path: $path) {
      id
      properties {
        id
        name
        type
        parentLegacyId
        popularOptions(parentLegacyId: null) {
          legacyResourceId
          name
          propertyLegacyId
          childrenPropertyIds
        }
        options(parentLegacyId: null) {
          legacyResourceId
          name
          propertyLegacyId
          childrenPropertyIds
        }
        settings {
          from
          step
          to
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  fragment BaseCategoryFields on Category {
    id
    name
    count
    path
    icon
  }

  query Category($path: String!) {
    category(path: $path) {
      id
      name
      path
      icon
      parent {
        ...BaseCategoryFields
        parent {
          ...BaseCategoryFields
        }
      }
    }
  }
`;

export const GET_CATEGORY_DETAILS = gql`
  fragment BaseCategoryFields on Category {
    id
    name
    count
    path
    icon
  }

  query CategoryDetails($path: String!) {
    category(path: $path) {
      id
      name
      path
      icon
      propertyOptions {
        name
        hasChildren
        legacyResourceId
        parentLegacyId
        propertyLegacyId
      }
      parent {
        ...BaseCategoryFields
        parent {
          ...BaseCategoryFields
        }
      }
      bundles {
        id
        count
        price
      }
      warningMessage
      isFreeLimitExceededForUser
      paidAdsBalance
    }
  }
`;

export const GET_CHILD_PROPERTY_OPTIONS = gql`
  query PropertyOptions($legacyId: ID!, $parentLegacyId: ID) {
    property(legacyId: $legacyId) {
      popularOptions(parentLegacyId: $parentLegacyId) {
        legacyResourceId
        name
        parentLegacyId
        propertyLegacyId
        childrenPropertyIds
      }
      options(parentLegacyId: $parentLegacyId) {
        legacyResourceId
        name
        parentLegacyId
        propertyLegacyId
        childrenPropertyIds
      }
    }
  }
`;

export const GET_CATEGORY_ENTITIES = gql`
  query CategoryEntities($keywords: String, $filters: AdFilterInput) {
    categoryEntities(keywords: $keywords, filters: $filters) {
      ... on Category {
        id
        name
        path
        slug
        icon
        count
        __typename
      }
      ... on PropertyOption {
        parentLegacyId
        propertyLegacyId
        legacyResourceId
        childrenPropertyIds
        name
        count
        __typename
      }
    }
  }
`;

export const GET_CATEGORY_ADS_COUNT = gql`
  query CategoryAdsCount(
    $path: String!
    $keywords: String
    $filters: AdFilterInput
  ) {
    category(path: $path, filters: $filters, keywords: $keywords) {
      count
    }
  }
`;

import {
  CATEGORY_SLUG_INDEX,
  SUBCATEGORY_SLUG_INDEX,
} from './useCurrentCategory.constants';
import { ROOT_CATEGORY_SLUG } from '@/constants/common';

export function getCurrentCategoryPath(
  slug: string | string[] | undefined
): string {
  return [slug?.[CATEGORY_SLUG_INDEX], slug?.[SUBCATEGORY_SLUG_INDEX]].reduce(
    (acc, s) => (s ? `${acc}/${s}` : acc),
    ROOT_CATEGORY_SLUG
  ) as string;
}

import { useQuery } from '@apollo/client';
import { GET_CATEGORY_PROPERTIES } from '@/api/queries/categories.queries';
import { GetCategoryPropertiesResponse } from '@/api/types/categories.types';
import { useCurrentCategory } from '@/hooks/useCurrentCategory/useCurrentCategory';

export const useCategoryProperties = () => {
  const { currentCategory } = useCurrentCategory();
  const {
    data,
    refetch: refetchCategoryProperties,
    loading,
  } = useQuery<GetCategoryPropertiesResponse>(GET_CATEGORY_PROPERTIES, {
    skip: !currentCategory?.path,
    variables: { path: currentCategory?.path },
  });

  const { id: categoryId, properties: categoryProperties } =
    data?.category ?? {};

  return {
    categoryId,
    categoryProperties,
    refetchCategoryProperties,
    isCategoriesLoading: loading,
  };
};

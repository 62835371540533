export enum AdStatus {
  Pending = 'pending',
  Published = 'approved',
  Rejected = 'rejected',
  Expired = 'expired',
  AwaitingPayment = 'awaiting_payment',
}

export interface SelectOption {
  label: string;
  value: string;
  dataStat?: string;
}

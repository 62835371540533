import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import * as S from './SearchPanel.styles';
import { Button } from '@/components/UI/Button/Button';
import { FiltersDrawer } from '@/components/FiltersDrawer/FiltersDrawer';
import {
  CloseIcon,
  FiltersIcon,
  SearchIcon,
} from '@/components/UI/Icons/Icons';
import { QUERY } from '@/constants/common';
import { useQueryParam } from '@/hooks/useQueryParam';
import { Badge } from '@/components/UI/Badge/Badge';
import { ROUTES } from '@/constants/routes';
import { getDataStatAttr, sleep } from '@/utils/helpers';
import { MIN_QUERY_LENGTH } from '@/components/SearchSuggestions/SearchSuggestions.constants';
import { useSearch } from '@/hooks/useSearch';
import { SearchSuggestions } from '@/components/SearchSuggestions/SearchSuggestions';
import { KeywordsSource } from '@/components/SearchSuggestions/SearchSuggestions.types';
import { useSearchStore } from '@/store/useSearchStore';
import { useIsTopBannerVisible } from '@/hooks/useIsTopBannerVisible';

interface SearchPanelProps {
  hasFilters?: boolean;
  hasPaddingTop?: boolean;
  dataStat?: string;
}

export const SearchPanel: React.FC<SearchPanelProps> = ({
  hasFilters = false, // TODO: change to true when business logic on BE will be ready
  hasPaddingTop = true,
  dataStat,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const search = useSearch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialSearchQuery] = useQueryParam(`${QUERY.q}[${QUERY.keywords}]`);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isInteractionHappened, setIsInteractionHappened] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const isTopBannerVisible = useIsTopBannerVisible();
  const [isFocused, setIsFocused] = useState(false);
  const [isSuggestionsOpened] = useSearchStore(state => [
    state.isSuggestionsOpened,
  ]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.target.value);
    setIsInteractionHappened(e.target.value.length >= MIN_QUERY_LENGTH);
  }

  function onFocus() {
    setIsFocused(true);
    setIsInteractionHappened(!!searchQuery);
  }

  async function onBlur() {
    await sleep(0);
    setIsFocused(false);
  }

  async function clear(event: React.MouseEvent) {
    event.preventDefault();
    await sleep(0);
    setSearchQuery('');
    inputRef.current?.focus();
  }

  function scrollToInput() {
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  function submit(e: React.FormEvent) {
    e.preventDefault();
    inputRef.current?.blur();
    search(searchQuery, { keywordsSource: KeywordsSource.Typewritten });
  }

  function toggleFilters() {
    setIsFiltersOpen(prev => !prev);
  }

  useEffect(() => {
    const handleStart = (pageUrl: string) => {
      setIsInteractionHappened(false);
      if (pageUrl === ROUTES.home) {
        setSearchQuery('');
      }
    };

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    };
  }, []);

  useEffect(() => {
    setSearchQuery(initialSearchQuery ?? '');
  }, [initialSearchQuery]);

  const offsetTop = useMemo(() => {
    if (searchInputRef.current) {
      const { height, top } = searchInputRef.current.getBoundingClientRect();
      const MARGIN = 10;
      return height + top - MARGIN;
    }

    return 0;
  }, [searchInputRef.current, isTopBannerVisible, isFocused]);

  const isClearButtonShown = hasFilters
    ? searchQuery && isFocused
    : searchQuery && isSuggestionsOpened;

  return (
    <S.Box ref={searchInputRef} $hasPaddingTop={hasPaddingTop}>
      <S.Form onSubmit={submit} action="/" $isFocused={isFocused}>
        <Button
          appearance="transparent"
          shape="square"
          height={42}
          type="submit"
          className="top-search__form-btn"
        >
          <SearchIcon fill={theme.palette.secondary.labels} />
        </Button>

        <S.Input
          id="q_keywords"
          {...getDataStatAttr({ dataStat })}
          ref={inputRef}
          type="search"
          autoCorrect="off"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck="false"
          placeholder={t('search.placeholder')}
          value={searchQuery}
          onChange={onChange}
          onFocus={onFocus}
          $hasBorderBottom={false}
          onBlur={onBlur}
          className="top-search__form-input"
        />

        {isClearButtonShown && (
          <S.CloseButton
            onClick={clear}
            onMouseDown={e => e.preventDefault()}
            appearance="transparent"
            hasPadding={false}
            data-testid="clear-button"
          >
            <CloseIcon width={13} fill={theme.palette.secondary.labels} />
          </S.CloseButton>
        )}
      </S.Form>

      <SearchSuggestions
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isInteractionHappened={isInteractionHappened}
        setIsInteractionHappened={setIsInteractionHappened}
        scrollToInput={scrollToInput}
        offsetTop={offsetTop}
      />

      {hasFilters && (
        <>
          <Badge value={filterCount}>
            <Button
              onClick={toggleFilters}
              shape="square"
              appearance="primaryOutlined"
              data-testid="filters-button"
              height={44}
              borderRadius={14}
            >
              <FiltersIcon fill={theme.palette.brand.main} />
            </Button>
          </Badge>

          <FiltersDrawer
            isOpen={isFiltersOpen}
            close={toggleFilters}
            setFilterCount={setFilterCount}
          />
        </>
      )}
    </S.Box>
  );
};

const categoriesMockAz = {
  categories: [
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
      name: 'Elektronika',
      path: 'elanlar/elektronika',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az telefonlar və planşetlərdən çeşidli aksesuarlara qədər hər növ yeni və işlənmiş elektronika məhsulları üzrə çox sərfəli təkliflərin ünvanıdır – həm alıcılar, həm də satıcılar üçün sərfəli.',
      count: 35838,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMQ',
          name: 'Audio və video',
          path: 'elanlar/elektronika/audio-video',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1608,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMg',
          name: 'Kompüter aksesuarları',
          path: 'elanlar/elektronika/komputer-aksesuarlari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1088,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMw',
          name: 'Oyunlar, pultlar və proqramlar',
          path: 'elanlar/elektronika/oyunlar-ve-programlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2170,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNA',
          name: 'Masaüstü kompüterlər',
          path: 'elanlar/elektronika/komputerler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 910,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNQ',
          name: 'Komponentlər və monitorlar',
          path: 'elanlar/elektronika/komputer-avadanliqi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2107,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNg',
          name: 'Planşet və elektron kitablar',
          path: 'elanlar/elektronika/plansetler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 700,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNw',
          name: 'Noutbuklar və netbuklar',
          path: 'elanlar/elektronika/noutbuklar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 3029,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOA',
          name: 'Ofis avadanlığı və istehlak materialları',
          path: 'elanlar/elektronika/ofis-avadanliqi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 819,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
          name: 'Telefonlar',
          path: 'elanlar/elektronika/telefonlar',
          count: 16001,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOQ',
          name: 'Nömrələr və SIM-kartlar',
          path: 'elanlar/elektronika/nomreler-ve-sim-kartlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2333,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMA',
          name: 'Fototexnika',
          path: 'elanlar/elektronika/fotoaparatlar-ve-linzalar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1068,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MA',
          name: 'Smart saat və qolbaqlar',
          path: 'elanlar/elektronika/smart-saat-ve-qolbaqlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1128,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MQ',
          name: 'Televizorlar və aksesuarlar',
          path: 'elanlar/elektronika/televizor-ve-aksesuarlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1968,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNw',
          name: 'Şəbəkə və server avadanlığı',
          path: 'elanlar/elektronika/sebeke-avadanligi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 909,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
      name: 'Nəqliyyat',
      path: 'elanlar/neqliyyat',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az avtomobil almaq və ya satmaq istəyənləri bir araya gətirir. Yeni və sürülmüş nəqliyyat vasitələrini, avtomobillər üçün ehtiyat hissə və aksesuarları əla qiymətlərlə alın və ya satın.',
      count: 34289,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mg',
          name: 'Avtomobillər',
          path: 'elanlar/neqliyyat/avtomobiller',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 6773,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
          name: 'Ehtiyat hissələri və aksesuarlar',
          path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
          count: 24577,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NA',
          name: 'Su nəqliyyatı',
          path: 'elanlar/neqliyyat/su-neqliyyati',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 48,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NQ',
          name: 'Motosikletlər və mopedlər',
          path: 'elanlar/neqliyyat/motosikletler-mopedler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 781,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Ng',
          name: 'Tikinti texnikası',
          path: 'elanlar/neqliyyat/tikinti-texnikasi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 280,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Nw',
          name: 'Aqrotexnika',
          path: 'elanlar/neqliyyat/aqrotexnika',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 497,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMw',
          name: 'Avtobuslar',
          path: 'elanlar/neqliyyat/avtobuslar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 16,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNA',
          name: 'Yük maşınları və qoşqular',
          path: 'elanlar/neqliyyat/yuk-masinlari-ve-qosqular',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 615,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcxMQ',
          name: 'Qeydiyyat nişanları',
          path: 'elanlar/neqliyyat/qeydiyyat-nisanlari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 702,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
      name: 'Daşınmaz əmlak',
      path: 'elanlar/dasinmaz-emlak',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az ev alqı satqısı, eləcə də kiraye ev elanları yerləşdirmək və tapmaq üçün ideal məkandır. Burada hər növ daşınmaz əmlakı (mənzillər, evler, ofislər və s.) rahatlıqla ala və ya qısa vaxtda sata bilərsiniz.',
      count: 30980,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNQ',
          name: 'Mənzillər',
          path: 'elanlar/dasinmaz-emlak/menziller',
          __typename: 'Category',
          count: 17585,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMQ',
          name: 'Həyət evləri, bağ evləri',
          path: 'elanlar/dasinmaz-emlak/heyet-evleri',
          __typename: 'Category',
          count: 7413,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMg',
          name: 'Torpaq',
          path: 'elanlar/dasinmaz-emlak/torpaq-sahesi',
          __typename: 'Category',
          count: 3131,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMw',
          name: 'Qarajlar',
          path: 'elanlar/dasinmaz-emlak/qarajlar',
          __typename: 'Category',
          count: 68,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNA',
          name: 'Xaricdə əmlak',
          path: 'elanlar/dasinmaz-emlak/xaricde-emlak',
          __typename: 'Category',
          count: 6,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNQ',
          name: 'Obyektlər və ofislər',
          path: 'elanlar/dasinmaz-emlak/obyektler-ve-ofisler',
          __typename: 'Category',
          count: 2777,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
      name: 'Ev və bağ üçün',
      path: 'elanlar/ev-ve-bag-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
      __typename: 'Category',
      metaDescription:
        'Təmir-tikinti üçün hər şey, mebel, yeni və islenmis ev esyalari, yeni və ikinci əl mətbəx ləvazimatları, məişət texnikası, bir sözlə, ev və bağ üçün əşyalar ilə bağlı hər şeyi burada tapa və sata bilərsiniz.',
      count: 26196,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OA',
          name: 'Təmir və tikinti',
          path: 'elanlar/ev-ve-bag-ucun/temir-tikinti',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 5497,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OQ',
          name: 'Mebellər',
          path: 'elanlar/ev-ve-bag-ucun/mebel',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 7660,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
          name: 'Məişət texnikası',
          path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
          count: 5932,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMw',
          name: 'Ərzaq',
          path: 'elanlar/ev-ve-bag-ucun/erzaq',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 433,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MQ',
          name: 'Qab-qacaq və mətbəx ləvazimatları',
          path: 'elanlar/ev-ve-bag-ucun/qab-qacaq',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2161,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mg',
          name: 'Bitkilər',
          path: 'elanlar/ev-ve-bag-ucun/bitkiler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 608,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mg',
          name: 'Xalçalar və aksesuarlar',
          path: 'elanlar/ev-ve-bag-ucun/xalcalar-aksesuarlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 610,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mw',
          name: 'Ev tekstili',
          path: 'elanlar/ev-ve-bag-ucun/ev-tekstili',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 544,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NA',
          name: 'Ev və bağ üçün işiqlandırma',
          path: 'elanlar/ev-ve-bag-ucun/ev-bag-ucun-ishiqlandirma',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 614,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NQ',
          name: 'Dekor və interyer',
          path: 'elanlar/ev-ve-bag-ucun/dekor-interyer',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1161,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Ng',
          name: 'Bağ və bostan',
          path: 'elanlar/ev-ve-bag-ucun/bag-bostan',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 700,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNQ',
          name: 'Ev təsərrüfatı malları',
          path: 'elanlar/ev-ve-bag-ucun/ev-teserrufati-mallari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 276,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
      name: 'Ehtiyat hissələri və aksesuarlar',
      path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F07%2F16%2F15%2F51%2F52%2F7df05b75-36e0-4ef8-a7c3-fa0b785ae9c1%2F12183_ogtQftp0VNM0b8HrfrDIcQ.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da avtomobil ehtiyat hissələri və aksesuarlar çox sərfəli qiymətlərlə təklif olunur. Mağazaları və maşın "ölüxana"larını gəzməyi unudun.',
      count: 24577,
      propertyOptions: [
        {
          name: 'Aksesuarlar',
          legacyResourceId: '4045',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Audio və video texnika',
          legacyResourceId: '4046',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Avto kosmetika və kimya',
          legacyResourceId: '4044',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Avtomobil üçün alətlər',
          legacyResourceId: '42255',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Diaqnostika cihazları',
          legacyResourceId: '7693',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Ehtiyat hissələri',
          legacyResourceId: '4047',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'GPS-naviqatorlar',
          legacyResourceId: '4043',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Şinlər, disklər və təkərlər',
          legacyResourceId: '4050',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Siqnalizasiyalar',
          legacyResourceId: '4048',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Videoqeydiyyatçılar',
          legacyResourceId: '7476',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Digər',
          legacyResourceId: '4051',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
        name: 'Nəqliyyat',
        count: 34289,
        path: 'elanlar/neqliyyat',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
        __typename: 'Category',
      },
      children: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
      name: 'Telefonlar',
      path: 'elanlar/elektronika/telefonlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F17%2F04%2F59%2F8fecaa9e-ac94-4edc-9a70-ddc6239b6e02%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
      metaDescription:
        'Yeni və işlənmiş telefonların böyük seçimi! İstənilən mobil telefonları Tap.Az-da!',
      count: 16001,
      propertyOptions: [
        {
          name: 'Acer',
          legacyResourceId: '3851',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Aiek',
          legacyResourceId: '7368',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Aksesuarlar',
          legacyResourceId: '3864',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Alcatel',
          legacyResourceId: '3852',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Apple iPhone',
          legacyResourceId: '3855',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Asus',
          legacyResourceId: '4180',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Blackberry',
          legacyResourceId: '3853',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Doogee',
          legacyResourceId: '7369',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Dublikat',
          legacyResourceId: '4188',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Ehtiyat hissələri',
          legacyResourceId: '7448',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Ericsson',
          legacyResourceId: '7375',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Fly',
          legacyResourceId: '7364',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Google',
          legacyResourceId: '38821',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hisense',
          legacyResourceId: '7541',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hoffmann',
          legacyResourceId: '7567',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Homtom',
          legacyResourceId: '7372',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Honor',
          legacyResourceId: '10108',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hope',
          legacyResourceId: '22438',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'HTC',
          legacyResourceId: '3854',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Huawei',
          legacyResourceId: '4118',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Infinix ',
          legacyResourceId: '39354',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'INOI',
          legacyResourceId: '43821',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Itel',
          legacyResourceId: '37014',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Jinga',
          legacyResourceId: '7619',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Keneksi',
          legacyResourceId: '7374',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Kgtel',
          legacyResourceId: '22326',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Leagoo',
          legacyResourceId: '7569',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Lenovo',
          legacyResourceId: '4126',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'LG',
          legacyResourceId: '3856',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Meizu',
          legacyResourceId: '7366',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Micromax',
          legacyResourceId: '7542',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Motorola',
          legacyResourceId: '3857',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Nokia',
          legacyResourceId: '3858',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'OnePlus',
          legacyResourceId: '7371',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'OPPO',
          legacyResourceId: '38970',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Oukitel',
          legacyResourceId: '7568',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Philips',
          legacyResourceId: '3859',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Realme',
          legacyResourceId: '21150',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Samsung',
          legacyResourceId: '3860',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Siemens',
          legacyResourceId: '3861',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Sony',
          legacyResourceId: '3862',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Sony Ericsson',
          legacyResourceId: '7362',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Stasionar telefonlar',
          legacyResourceId: '3866',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'TCL',
          legacyResourceId: '36868',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Tecno',
          legacyResourceId: '36154',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Umi',
          legacyResourceId: '7373',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Vertu',
          legacyResourceId: '3863',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Vivo',
          legacyResourceId: '43728',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Xiaomi',
          legacyResourceId: '7367',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'ZTE',
          legacyResourceId: '7370',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Digər',
          legacyResourceId: '3867',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
        name: 'Elektronika',
        count: 35838,
        path: 'elanlar/elektronika',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
        __typename: 'Category',
      },
      children: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
      name: 'Xidmətlər və biznes',
      path: 'elanlar/xidmetler',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da təqdim olunan çoxsaylı xidmətlərdən (elektrik, fotoqraf, repetitor və s.) yararlana və ya mütəxəssisi olduğunuz sahə üzrə xidmətlərinizi təklif edərək davamlı qazanc əldə edə bilərsiniz.',
      count: 11261,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OA',
          name: 'Avadanlığın icarəsi',
          path: 'elanlar/xidmetler/avadanliqin-icaresi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 182,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMQ',
          name: 'Biznes üçün avadanlıq',
          path: 'elanlar/xidmetler/biznes-avadaliqi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 6291,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Nw',
          name: 'Avadanlıqların quraşdırılması',
          path: 'elanlar/xidmetler/avadanliqin-qurasdirilmasi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 57,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MQ',
          name: 'Avtoservis və diaqnostika ',
          path: 'elanlar/xidmetler/avtoservis-ve-diaqnostika',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 113,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mw',
          name: 'Dayələr, baxıcılar',
          path: 'elanlar/xidmetler/dayeler-baxicilar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 49,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NQ',
          name: 'Foto və video çəkiliş xidmətləri',
          path: 'elanlar/xidmetler/foto-ve-video-cekilis',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 42,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NA',
          name: 'Gözəllik, sağlamlıq',
          path: 'elanlar/xidmetler/gozellik-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 55,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mg',
          name: 'Hüquq xidmətləri',
          path: 'elanlar/xidmetler/huquq-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 20,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mw',
          name: 'IT, internet, telekom',
          path: 'elanlar/xidmetler/komputer-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 141,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNg',
          name: 'Logistika',
          path: 'elanlar/xidmetler/logistika',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 218,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OA',
          name: 'Mebel yığılması və təmiri',
          path: 'elanlar/xidmetler/sifarisle-mebel',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 164,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OA',
          name: 'Mühasibat xidmətləri',
          path: 'elanlar/xidmetler/muhasibat-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 44,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mg',
          name: 'Musiqi, əyləncə və tədbirlər',
          path: 'elanlar/xidmetler/tedbirlerin-teskilati',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 58,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OQ',
          name: 'Nəqliyyat vasitələrinin icarəsi',
          path: 'elanlar/xidmetler/neqliyyat-icaresi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 485,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Nw',
          name: 'Qidalanma, keyterinq',
          path: 'elanlar/xidmetler/keyterinq-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 69,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NQ',
          name: 'Reklam, dizayn və poliqrafiya',
          path: 'elanlar/xidmetler/reklam-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 290,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Ng',
          name: 'Sığorta xidmətləri',
          path: 'elanlar/xidmetler/sigorta-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 16,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MQ',
          name: 'Təhlükəsizlik sistemləri',
          path: 'elanlar/xidmetler/tehlukesizlik-sistemleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1199,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MA',
          name: 'Təlim, hazırlıq kursları',
          path: 'elanlar/xidmetler/telim-hazirliq-kurslari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 278,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
          name: 'Təmir və tikinti',
          path: 'elanlar/xidmetler/temir-tikinti',
          count: 1008,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MA',
          name: 'Təmizlik',
          path: 'elanlar/xidmetler/temizlik-xidmeti',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 79,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NA',
          name: 'Tərcümə',
          path: 'elanlar/xidmetler/tercume-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 15,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Nw',
          name: 'Texnika təmiri',
          path: 'elanlar/xidmetler/texnika-temiri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 238,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MQ',
          name: 'Tibbi xidmətlər',
          path: 'elanlar/xidmetler/tibbi-xidmetler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 25,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OQ',
          name: 'Digər',
          path: 'elanlar/xidmetler/diger-xidmetler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 125,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
      name: 'Şəxsi əşyalar',
      path: 'elanlar/sexsi-esyalar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az istifadəçiləri burada yeni və işlənmiş geyimlərini, aksesuarlarını və digər əşyalarını sataraq gəlir əldə edir, həmçinin itirdikləri və ya tapdıqları əşyalar barədə elanlar yerləşdirir.',
      count: 11240,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNw',
          name: 'Geyim və ayaqqabılar',
          path: 'elanlar/sexsi-esyalar/geyim-ayaqqabilar',
          __typename: 'Category',
          count: 4898,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNg',
          name: 'Saat və zinət əşyaları',
          path: 'elanlar/sexsi-esyalar/saatlar-zinet-esyalari',
          __typename: 'Category',
          count: 2401,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwOA',
          name: 'Aksesuarlar',
          path: 'elanlar/sexsi-esyalar/aksesuarlar',
          __typename: 'Category',
          count: 1413,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOA',
          name: 'Sağlamlıq və gözəllik',
          path: 'elanlar/sexsi-esyalar/gozellik-saglamliq',
          __typename: 'Category',
          count: 2195,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0Mw',
          name: 'İtmiş əşyalar',
          path: 'elanlar/sexsi-esyalar/itmis-esyalar',
          __typename: 'Category',
          count: 31,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OA',
          name: 'Elektron siqaretlər və tütün qızdırıcıları',
          path: 'elanlar/sexsi-esyalar/elektron-siqaretler',
          __typename: 'Category',
          count: 302,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
      name: 'Hobbi və asudə',
      path: 'elanlar/hobbi-ve-asude',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
      __typename: 'Category',
      metaDescription:
        'Asudə vaxtınızda məşğul olmaq üçün maraqlı hobbi axtarırsınız? Yaxın həftələrdə səyahətə çıxmaq istəyirsiniz? Bəs ürəyinizə yatan bir insanla tanış olmaq necə? Elədirsə, bura tam sizlikdir.',
      count: 6754,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMg',
          name: 'Biletlər və səyahət',
          path: 'elanlar/hobbi-ve-asude/turlar-ve-biletler',
          __typename: 'Category',
          count: 133,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMw',
          name: 'Velosipedlər',
          path: 'elanlar/hobbi-ve-asude/velosipedler',
          __typename: 'Category',
          count: 1428,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNA',
          name: 'Kolleksiyalar',
          path: 'elanlar/hobbi-ve-asude/kolleksiyalar',
          __typename: 'Category',
          count: 1199,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNQ',
          name: 'Musiqi alətləri',
          path: 'elanlar/hobbi-ve-asude/musiqi-aletleri',
          __typename: 'Category',
          count: 1224,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNg',
          name: 'İdman və asudə',
          path: 'elanlar/hobbi-ve-asude/idman-ve-asude',
          __typename: 'Category',
          count: 1585,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNw',
          name: 'Kitab və jurnallar',
          path: 'elanlar/hobbi-ve-asude/kitab-ve-jurnallar',
          __typename: 'Category',
          count: 717,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOA',
          name: 'Kempinq, ovçuluq və balıqçılıq',
          path: 'elanlar/hobbi-ve-asude/kempinq-ovculuq-baliqciliq',
          __typename: 'Category',
          count: 360,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNw',
          name: 'Tanışlıq',
          path: 'elanlar/hobbi-ve-asude/tanisliq',
          __typename: 'Category',
          count: 108,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
      name: 'Məişət texnikası',
      path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F15%2F06%2F21%2F0ec2af6c-0768-4565-8ace-6916db612e3a%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da minlərlə məişət texnikası elanı mövcuddur. İstəyinizə uyğun soyuducu, paltaryuyan və digər əşyaları burada sərfəli qiymətə ala bilərsiniz.',
      count: 5932,
      propertyOptions: [
        {
          name: 'Aspiratorlar',
          legacyResourceId: '7657',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Blenderlər, mikserlər, mətbəx kombaynları',
          legacyResourceId: '7629',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Blinçik və vafli aparatı',
          legacyResourceId: '34686',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Elektrik çaydanlar və termopotlar',
          legacyResourceId: '7628',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Elektrik samovarlar',
          legacyResourceId: '7687',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Ətçəkən maşınlar',
          legacyResourceId: '7632',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Fritürlar',
          legacyResourceId: '34671',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hava nəmləndiriciləri və təmizləyiciləri',
          legacyResourceId: '38103',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Kondisionerlər',
          legacyResourceId: '3895',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Mikrodalğalı sobalar',
          legacyResourceId: '3891',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Mini sobalar',
          legacyResourceId: '34629',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Multibişiricilər',
          legacyResourceId: '34728',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Paltaryuyanlar',
          legacyResourceId: '3889',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Plitələr',
          legacyResourceId: '3892',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Qabyuyanlar',
          legacyResourceId: '3893',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Qəhvədəmləyənlər və qəhvə maşınları',
          legacyResourceId: '7633',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Qızdırıcılar',
          legacyResourceId: '3896',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Quraşdırılan sobalar',
          legacyResourceId: '34628',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Samovarlar',
          legacyResourceId: '7686',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Sərinkeşlər',
          legacyResourceId: '34725',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Şirəçəkənlər',
          legacyResourceId: '7634',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Soyuducular',
          legacyResourceId: '3894',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Su dispenserləri və kulerləri',
          legacyResourceId: '34627',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Su filtrləri və pompaları',
          legacyResourceId: '34621',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Süd separatorları',
          legacyResourceId: '34727',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Termometrlər',
          legacyResourceId: '34669',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Tərəzilər',
          legacyResourceId: '34685',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Tikiş maşınları',
          legacyResourceId: '7631',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Tosterlər və çörəkbişirənlər',
          legacyResourceId: '7630',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Tozsoranlar',
          legacyResourceId: '3888',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Ütülər',
          legacyResourceId: '3890',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Vakuum paketləyicilər',
          legacyResourceId: '34684',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Digər',
          legacyResourceId: '3898',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
        name: 'Ev və bağ üçün',
        count: 26196,
        path: 'elanlar/ev-ve-bag-ucun',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
        __typename: 'Category',
      },
      children: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
      name: 'İş elanları',
      path: 'elanlar/is-elanlari',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az hər zaman olduğu kimi iş var və ya is axtariram deyənlərin işini asanlaşdırır. Yeni is elanlari 2022 mövsümü üzrə daim yenilənir, yüzlərlə şəxs isə qısa zamanda axtardığı vakansiyalar üzrə iş tapır.',
      count: 5382,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OA',
          name: 'Vakansiyalar',
          path: 'elanlar/is-elanlari/vakansiyalar',
          __typename: 'Category',
          count: 3064,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'İş elanları',
            count: 5382,
            path: 'elanlar/is-elanlari',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OQ',
          name: 'İş axtarıram',
          path: 'elanlar/is-elanlari/is-axtariram',
          __typename: 'Category',
          count: 2318,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'İş elanları',
            count: 5382,
            path: 'elanlar/is-elanlari',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
      name: 'Heyvanlar',
      path: 'elanlar/heyvanlar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az müxtəlif heyvanlar üçün yeni sahib tapmaqda və ya onlara sahiblənməkdə sizə yardımçı olacaq. Burada axtardığınız heyvan növlərini, onlar üçün müxtəlif qida, əşya və oyuncaqları tapa bilərsiniz.',
      count: 5379,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NA',
          name: 'İtlər',
          path: 'elanlar/heyvanlar/itler',
          __typename: 'Category',
          count: 1210,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NQ',
          name: 'Pişiklər',
          path: 'elanlar/heyvanlar/pisikler',
          __typename: 'Category',
          count: 747,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Ng',
          name: 'Quşlar',
          path: 'elanlar/heyvanlar/quslar',
          __typename: 'Category',
          count: 1606,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Nw',
          name: 'Akvariumlar və balıqlar',
          path: 'elanlar/heyvanlar/baliqlar-akvariumlar',
          __typename: 'Category',
          count: 471,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OQ',
          name: 'K/t heyvanları',
          path: 'elanlar/heyvanlar/kt-heyvanlari',
          __typename: 'Category',
          count: 480,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OA',
          name: 'Digər heyvanlar',
          path: 'elanlar/heyvanlar/diger-heyvanlar',
          __typename: 'Category',
          count: 9,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMA',
          name: 'Heyvanlar üçün məhsullar',
          path: 'elanlar/heyvanlar/heyvanlar-ucun-mehsullar',
          __typename: 'Category',
          count: 684,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMA',
          name: 'Dovşanlar',
          path: 'elanlar/heyvanlar/dovsanlar',
          __typename: 'Category',
          count: 84,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMQ',
          name: 'Atlar',
          path: 'elanlar/heyvanlar/atlar',
          __typename: 'Category',
          count: 47,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMg',
          name: 'Gəmiricilər',
          path: 'elanlar/heyvanlar/gemiriciler',
          __typename: 'Category',
          count: 41,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
      name: 'Uşaq aləmi',
      path: 'elanlar/usaqlar-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da hər yaşda uşaqlar üçün axtardığınız əşyaları (oyuncaqlar, geyim, usaq belekleri və s.) tapa və ya sata bilərsiniz. Məhsullar olduqca sərfəli qiymətə təqdim olunur və qısa müddətdə alıcı tapır.',
      count: 5219,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OQ',
          name: 'Avtomobil oturacaqları',
          path: 'elanlar/usaqlar-ucun/avtomobil-oturacaqlari',
          __typename: 'Category',
          count: 112,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MA',
          name: 'Oyuncaqlar',
          path: 'elanlar/usaqlar-ucun/oyuncaqlar',
          __typename: 'Category',
          count: 1122,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MQ',
          name: 'Uşaq arabaları',
          path: 'elanlar/usaqlar-ucun/usaq-arabalari',
          __typename: 'Category',
          count: 730,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Nw',
          name: 'Uşaq avtomobilləri',
          path: 'elanlar/usaqlar-ucun/usaq-avtomobilleri',
          __typename: 'Category',
          count: 207,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mw',
          name: 'Çarpayılar və beşiklər',
          path: 'elanlar/usaqlar-ucun/usaq-carpayilari-ve-beshikler',
          __typename: 'Category',
          count: 979,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Ng',
          name: 'Uşaq daşıyıcıları',
          path: 'elanlar/usaqlar-ucun/usaq-dasiyicilari',
          __typename: 'Category',
          count: 79,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mg',
          name: 'Uşaq geyimi',
          path: 'elanlar/usaqlar-ucun/usaq-geyimleri',
          __typename: 'Category',
          count: 660,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mw',
          name: 'Uşaq mebeli',
          path: 'elanlar/usaqlar-ucun/mebel',
          __typename: 'Category',
          count: 266,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NA',
          name: 'Uşaq qidası və bəslənməsi',
          path: 'elanlar/usaqlar-ucun/usaq-yemekleri',
          __typename: 'Category',
          count: 99,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NQ',
          name: 'Sürüşkənlər və meydançalar',
          path: 'elanlar/usaqlar-ucun/usaq-suruskenleri-ve-oyun-meydancalari',
          __typename: 'Category',
          count: 43,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mg',
          name: 'Manejlər',
          path: 'elanlar/usaqlar-ucun/manejler',
          __typename: 'Category',
          count: 42,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OQ',
          name: 'Məktəblilər üçün',
          path: 'elanlar/usaqlar-ucun/mekteb-levazimatlari',
          __typename: 'Category',
          count: 474,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MA',
          name: 'Yürütəclər',
          path: 'elanlar/usaqlar-ucun/yurutecler',
          __typename: 'Category',
          count: 107,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOA',
          name: 'Hamam və gigiyena',
          path: 'elanlar/usaqlar-ucun/usaq-gigiyenasi',
          __typename: 'Category',
          count: 47,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOQ',
          name: 'Uşaq tekstili',
          path: 'elanlar/usaqlar-ucun/usaq-tekstili',
          __typename: 'Category',
          count: 31,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NA',
          name: 'Qidalanma oturacaqları',
          path: 'elanlar/usaqlar-ucun/qidalanma-ucun-usaq-oturacaqlar',
          __typename: 'Category',
          count: 198,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NQ',
          name: 'Digər',
          path: 'elanlar/usaqlar-ucun/her-sey',
          __typename: 'Category',
          count: 23,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
      name: 'Təmir və tikinti',
      path: 'elanlar/xidmetler/temir-tikinti',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2025%2F02%2F10%2F16%2F59%2F18%2Ff1006921-b3d7-4153-a336-d3f68ade685f%2F1957_PTAk9XsjQ8he6mTnwxv8JQ.png',
      __typename: 'Category',
      metaDescription: 'Tap.Az saytında geniş təmir və tikinti seçimləri',
      count: 1008,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
        name: 'Xidmətlər və biznes',
        count: 11261,
        path: 'elanlar/xidmetler',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
        __typename: 'Category',
      },
      children: [],
    },
  ],
};

const categoriesMockRu = {
  categories: [
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
      name: 'Электроника',
      path: 'elanlar/elektronika',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
      __typename: 'Category',
      metaDescription:
        'Аудио и видео, компьютерные аксессуары, телефоны, ноутбуки, нетбуки, настольные компьютеры, фототехника, планшеты, электронные книги, комплектующие, мониторы, игры, приставки, программы, номера и SIM-карты, оргтехника и расходники',
      count: 35838,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMQ',
          name: 'Аудио и видео',
          path: 'elanlar/elektronika/audio-video',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1608,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMg',
          name: 'Компьютерные аксессуары',
          path: 'elanlar/elektronika/komputer-aksesuarlari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1088,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMw',
          name: 'Игры, приставки и программы',
          path: 'elanlar/elektronika/oyunlar-ve-programlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2170,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNA',
          name: 'Настольные компьютеры',
          path: 'elanlar/elektronika/komputerler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 910,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNQ',
          name: 'Комплектующие и мониторы',
          path: 'elanlar/elektronika/komputer-avadanliqi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2107,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNg',
          name: 'Планшеты и электронные книги',
          path: 'elanlar/elektronika/plansetler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 700,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNw',
          name: 'Ноутбуки и нетбуки',
          path: 'elanlar/elektronika/noutbuklar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 3029,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOA',
          name: 'Оргтехника и расходники',
          path: 'elanlar/elektronika/ofis-avadanliqi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 819,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
          name: 'Мобильные телефоны',
          path: 'elanlar/elektronika/telefonlar',
          count: 16001,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOQ',
          name: 'Номера и SIM-карты',
          path: 'elanlar/elektronika/nomreler-ve-sim-kartlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2333,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMA',
          name: 'Фототехника',
          path: 'elanlar/elektronika/fotoaparatlar-ve-linzalar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1068,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MA',
          name: 'Смарт часы и браслеты',
          path: 'elanlar/elektronika/smart-saat-ve-qolbaqlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1128,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MQ',
          name: 'Телевизоры и аксессуары',
          path: 'elanlar/elektronika/televizor-ve-aksesuarlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1968,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNw',
          name: 'Сетевое и серверное оборудование',
          path: 'elanlar/elektronika/sebeke-avadanligi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 35838,
            path: 'elanlar/elektronika',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 909,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
      name: 'Транспорт',
      path: 'elanlar/neqliyyat',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
      __typename: 'Category',
      metaDescription:
        'Автомобили, автозапчасти, аксессуары, автобусы и техника, мотоциклы, мопеды, водный транспорт, агротехника ',
      count: 34289,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mg',
          name: 'Легковые автомобили',
          path: 'elanlar/neqliyyat/avtomobiller',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 6773,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
          name: 'Автозапчасти и аксессуары',
          path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
          count: 24577,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NA',
          name: 'Водный транспорт',
          path: 'elanlar/neqliyyat/su-neqliyyati',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 48,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NQ',
          name: 'Мотоциклы и мопеды',
          path: 'elanlar/neqliyyat/motosikletler-mopedler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 781,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Ng',
          name: 'Строительная техника',
          path: 'elanlar/neqliyyat/tikinti-texnikasi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 280,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Nw',
          name: 'Агротехника',
          path: 'elanlar/neqliyyat/aqrotexnika',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 497,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMw',
          name: 'Автобусы',
          path: 'elanlar/neqliyyat/avtobuslar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 16,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNA',
          name: 'Грузовики и прицепы',
          path: 'elanlar/neqliyyat/yuk-masinlari-ve-qosqular',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 615,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcxMQ',
          name: 'Регистрационные номера',
          path: 'elanlar/neqliyyat/qeydiyyat-nisanlari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 34289,
            path: 'elanlar/neqliyyat',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 702,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
      name: 'Недвижимость',
      path: 'elanlar/dasinmaz-emlak',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
      __typename: 'Category',
      metaDescription:
        'Квартиры, дома, дачи, коттеджи, земельные участки, объекты, офисы, гаражи, недвижимость за рубежом',
      count: 30980,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNQ',
          name: 'Квартиры',
          path: 'elanlar/dasinmaz-emlak/menziller',
          __typename: 'Category',
          count: 17585,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMQ',
          name: 'Дома, дачи, коттеджи',
          path: 'elanlar/dasinmaz-emlak/heyet-evleri',
          __typename: 'Category',
          count: 7413,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMg',
          name: 'Земельные участки',
          path: 'elanlar/dasinmaz-emlak/torpaq-sahesi',
          __typename: 'Category',
          count: 3131,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMw',
          name: 'Гаражи',
          path: 'elanlar/dasinmaz-emlak/qarajlar',
          __typename: 'Category',
          count: 68,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNA',
          name: 'Недвижимость за рубежом',
          path: 'elanlar/dasinmaz-emlak/xaricde-emlak',
          __typename: 'Category',
          count: 6,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNQ',
          name: 'Объекты и офисы',
          path: 'elanlar/dasinmaz-emlak/obyektler-ve-ofisler',
          __typename: 'Category',
          count: 2777,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 30980,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
      name: 'Для дома и дачи',
      path: 'elanlar/ev-ve-bag-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
      __typename: 'Category',
      metaDescription:
        'Ремонт и строительство, мебель и интерьер, бытовая техника, посуда и товары для кухни, растения',
      count: 26196,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OA',
          name: 'Ремонт и строительство',
          path: 'elanlar/ev-ve-bag-ucun/temir-tikinti',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 5497,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OQ',
          name: 'Мебель',
          path: 'elanlar/ev-ve-bag-ucun/mebel',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 7660,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
          name: 'Бытовая техника',
          path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
          count: 5932,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMw',
          name: 'Продукты питания',
          path: 'elanlar/ev-ve-bag-ucun/erzaq',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 433,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MQ',
          name: 'Посуда и товары для кухни',
          path: 'elanlar/ev-ve-bag-ucun/qab-qacaq',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 2161,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mg',
          name: 'Растения',
          path: 'elanlar/ev-ve-bag-ucun/bitkiler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 608,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mg',
          name: 'Ковры и аксессуары',
          path: 'elanlar/ev-ve-bag-ucun/xalcalar-aksesuarlar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 610,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mw',
          name: 'Домашний текстиль',
          path: 'elanlar/ev-ve-bag-ucun/ev-tekstili',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 544,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NA',
          name: 'Освещение для дома и дачи',
          path: 'elanlar/ev-ve-bag-ucun/ev-bag-ucun-ishiqlandirma',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 614,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NQ',
          name: 'Декор и интерьер',
          path: 'elanlar/ev-ve-bag-ucun/dekor-interyer',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1161,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Ng',
          name: 'Дача и сад',
          path: 'elanlar/ev-ve-bag-ucun/bag-bostan',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 700,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNQ',
          name: 'Хозяйственные товары',
          path: 'elanlar/ev-ve-bag-ucun/ev-teserrufati-mallari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 26196,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 276,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
      name: 'Автозапчасти и аксессуары',
      path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F07%2F16%2F15%2F51%2F52%2F7df05b75-36e0-4ef8-a7c3-fa0b785ae9c1%2F12183_ogtQftp0VNM0b8HrfrDIcQ.png',
      __typename: 'Category',
      metaDescription:
        'Продажа аксессуаров и запчастей для автомобилей – Регистрационные номера, GPS-навигаторы.',
      count: 24577,
      propertyOptions: [
        {
          name: 'Аксессуары',
          legacyResourceId: '4045',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Аудио и видеотехника',
          legacyResourceId: '4046',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Автокосметика и автохимия',
          legacyResourceId: '4044',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Автоинструменты',
          legacyResourceId: '42255',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Диагностические приборы',
          legacyResourceId: '7693',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Запчасти',
          legacyResourceId: '4047',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'GPS-навигаторы',
          legacyResourceId: '4043',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Шины, диски и колёса',
          legacyResourceId: '4050',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Противоугонные устройства',
          legacyResourceId: '4048',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Видеорегистраторы',
          legacyResourceId: '7476',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
        {
          name: 'Другое',
          legacyResourceId: '4051',
          parentLegacyId: null,
          propertyLegacyId: '798',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
        name: 'Транспорт',
        count: 34289,
        path: 'elanlar/neqliyyat',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
        __typename: 'Category',
      },
      children: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
      name: 'Мобильные телефоны',
      path: 'elanlar/elektronika/telefonlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F17%2F04%2F59%2F8fecaa9e-ac94-4edc-9a70-ddc6239b6e02%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
      metaDescription:
        'Большой выбор новых и бывших в употреблении телефонов! Любые модели мобильных телефонов на Tap.Az!',
      count: 16001,
      propertyOptions: [
        {
          name: 'Acer',
          legacyResourceId: '3851',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Aiek',
          legacyResourceId: '7368',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Аксессуары',
          legacyResourceId: '3864',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Alcatel',
          legacyResourceId: '3852',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Apple iPhone',
          legacyResourceId: '3855',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Asus',
          legacyResourceId: '4180',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Blackberry',
          legacyResourceId: '3853',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Doogee',
          legacyResourceId: '7369',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Дубликат',
          legacyResourceId: '4188',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Запчасти',
          legacyResourceId: '7448',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Ericsson',
          legacyResourceId: '7375',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Fly',
          legacyResourceId: '7364',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Google',
          legacyResourceId: '38821',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hisense',
          legacyResourceId: '7541',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hoffmann',
          legacyResourceId: '7567',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Homtom',
          legacyResourceId: '7372',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Honor',
          legacyResourceId: '10108',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Hope',
          legacyResourceId: '22438',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'HTC',
          legacyResourceId: '3854',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Huawei',
          legacyResourceId: '4118',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Infinix ',
          legacyResourceId: '39354',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'INOI',
          legacyResourceId: '43821',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Itel',
          legacyResourceId: '37014',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Jinga',
          legacyResourceId: '7619',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Keneksi',
          legacyResourceId: '7374',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Kgtel',
          legacyResourceId: '22326',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Leagoo',
          legacyResourceId: '7569',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Lenovo',
          legacyResourceId: '4126',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'LG',
          legacyResourceId: '3856',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Meizu',
          legacyResourceId: '7366',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Micromax',
          legacyResourceId: '7542',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Motorola',
          legacyResourceId: '3857',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Nokia',
          legacyResourceId: '3858',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'OnePlus',
          legacyResourceId: '7371',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'OPPO',
          legacyResourceId: '38970',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Oukitel',
          legacyResourceId: '7568',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Philips',
          legacyResourceId: '3859',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Realme',
          legacyResourceId: '21150',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Samsung',
          legacyResourceId: '3860',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Siemens',
          legacyResourceId: '3861',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Sony',
          legacyResourceId: '3862',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Sony Ericsson',
          legacyResourceId: '7362',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Стационарные телефоны',
          legacyResourceId: '3866',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'TCL',
          legacyResourceId: '36868',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Tecno',
          legacyResourceId: '36154',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Umi',
          legacyResourceId: '7373',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Vertu',
          legacyResourceId: '3863',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Vivo',
          legacyResourceId: '43728',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Xiaomi',
          legacyResourceId: '7367',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'ZTE',
          legacyResourceId: '7370',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
        {
          name: 'Другое',
          legacyResourceId: '3867',
          parentLegacyId: null,
          propertyLegacyId: '749',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
        name: 'Электроника',
        count: 35838,
        path: 'elanlar/elektronika',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
        __typename: 'Category',
      },
      children: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
      name: 'Услуги и бизнес',
      path: 'elanlar/xidmetler',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
      __typename: 'Category',
      metaDescription:
        'Аренда и установка оборудования, ремонт и строительство, установка охранных систем, реклама, дизайн и полиграфия, ремонт техники, сборка и реставрация мебели, перевод, обучение, подготовительные курсы, IT, интернет, музыка, развлечения и мероприятия, пита',
      count: 11261,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OA',
          name: 'Аренда оборудования',
          path: 'elanlar/xidmetler/avadanliqin-icaresi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 182,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMQ',
          name: 'Оборудование для бизнеса',
          path: 'elanlar/xidmetler/biznes-avadaliqi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 6291,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Nw',
          name: 'Установка оборудования',
          path: 'elanlar/xidmetler/avadanliqin-qurasdirilmasi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 57,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MQ',
          name: 'Автосервис и диагностика',
          path: 'elanlar/xidmetler/avtoservis-ve-diaqnostika',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 113,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mw',
          name: 'Няни, сиделки',
          path: 'elanlar/xidmetler/dayeler-baxicilar',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 49,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NQ',
          name: 'Фото и видеосъёмка',
          path: 'elanlar/xidmetler/foto-ve-video-cekilis',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 42,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NA',
          name: 'Красота, здоровье',
          path: 'elanlar/xidmetler/gozellik-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 55,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mg',
          name: 'Юридические услуги',
          path: 'elanlar/xidmetler/huquq-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 20,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mw',
          name: 'IT, интернет, телеком',
          path: 'elanlar/xidmetler/komputer-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 141,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNg',
          name: 'Логистика',
          path: 'elanlar/xidmetler/logistika',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 218,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OA',
          name: 'Сборка и ремонт мебели',
          path: 'elanlar/xidmetler/sifarisle-mebel',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 164,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OA',
          name: 'Бухгалтерские услуги',
          path: 'elanlar/xidmetler/muhasibat-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 44,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mg',
          name: 'Музыка и мероприятия',
          path: 'elanlar/xidmetler/tedbirlerin-teskilati',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 58,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OQ',
          name: 'Аренда транспорта',
          path: 'elanlar/xidmetler/neqliyyat-icaresi',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 485,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Nw',
          name: 'Питание, кейтеринг',
          path: 'elanlar/xidmetler/keyterinq-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 69,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NQ',
          name: 'Реклама, дизайн и полиграфия',
          path: 'elanlar/xidmetler/reklam-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 290,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Ng',
          name: 'Страхование',
          path: 'elanlar/xidmetler/sigorta-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 16,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MQ',
          name: 'Системы безопасности',
          path: 'elanlar/xidmetler/tehlukesizlik-sistemleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 1199,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MA',
          name: 'Обучение и курсы',
          path: 'elanlar/xidmetler/telim-hazirliq-kurslari',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 278,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
          name: 'Ремонт и строительство',
          path: 'elanlar/xidmetler/temir-tikinti',
          count: 1008,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MA',
          name: 'Уборка',
          path: 'elanlar/xidmetler/temizlik-xidmeti',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 79,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NA',
          name: 'Перевод',
          path: 'elanlar/xidmetler/tercume-xidmetleri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 15,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Nw',
          name: 'Ремонт техники',
          path: 'elanlar/xidmetler/texnika-temiri',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 238,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MQ',
          name: 'Медицинские услуги',
          path: 'elanlar/xidmetler/tibbi-xidmetler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 25,
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OQ',
          name: 'Другое',
          path: 'elanlar/xidmetler/diger-xidmetler',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 11261,
            path: 'elanlar/xidmetler',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
            __typename: 'Category',
          },
          __typename: 'Category',
          count: 125,
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
      name: 'Личные вещи',
      path: 'elanlar/sexsi-esyalar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
      __typename: 'Category',
      metaDescription:
        'Одежда и обувь, часы и украшения, аксессуары, красота и здоровье, утерянные вещи',
      count: 11240,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNw',
          name: 'Одежда и обувь',
          path: 'elanlar/sexsi-esyalar/geyim-ayaqqabilar',
          __typename: 'Category',
          count: 4898,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNg',
          name: 'Часы и украшения',
          path: 'elanlar/sexsi-esyalar/saatlar-zinet-esyalari',
          __typename: 'Category',
          count: 2401,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwOA',
          name: 'Аксессуары',
          path: 'elanlar/sexsi-esyalar/aksesuarlar',
          __typename: 'Category',
          count: 1413,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOA',
          name: 'Красота и здоровье',
          path: 'elanlar/sexsi-esyalar/gozellik-saglamliq',
          __typename: 'Category',
          count: 2195,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0Mw',
          name: 'Утерянные вещи',
          path: 'elanlar/sexsi-esyalar/itmis-esyalar',
          __typename: 'Category',
          count: 31,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OA',
          name: 'Электронные сигареты и системы нагревания табака',
          path: 'elanlar/sexsi-esyalar/elektron-siqaretler',
          __typename: 'Category',
          count: 302,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 11240,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
      name: 'Хобби и отдых',
      path: 'elanlar/hobbi-ve-asude',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
      __typename: 'Category',
      metaDescription:
        'Билеты и путешествия, велосипеды, коллекционирование, спорт и отдых, музыкальные инструменты, книги и журналы, знакомства, охота и рыбалка',
      count: 6754,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMg',
          name: 'Билеты и путешествия',
          path: 'elanlar/hobbi-ve-asude/turlar-ve-biletler',
          __typename: 'Category',
          count: 133,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMw',
          name: 'Велосипеды',
          path: 'elanlar/hobbi-ve-asude/velosipedler',
          __typename: 'Category',
          count: 1428,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNA',
          name: 'Коллекции',
          path: 'elanlar/hobbi-ve-asude/kolleksiyalar',
          __typename: 'Category',
          count: 1199,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNQ',
          name: 'Музыкальные инструменты',
          path: 'elanlar/hobbi-ve-asude/musiqi-aletleri',
          __typename: 'Category',
          count: 1224,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNg',
          name: 'Спорт и отдых',
          path: 'elanlar/hobbi-ve-asude/idman-ve-asude',
          __typename: 'Category',
          count: 1585,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNw',
          name: 'Книги и журналы',
          path: 'elanlar/hobbi-ve-asude/kitab-ve-jurnallar',
          __typename: 'Category',
          count: 717,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOA',
          name: 'Кемпинг, охота и рыбалка',
          path: 'elanlar/hobbi-ve-asude/kempinq-ovculuq-baliqciliq',
          __typename: 'Category',
          count: 360,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNw',
          name: 'Знакомства',
          path: 'elanlar/hobbi-ve-asude/tanisliq',
          __typename: 'Category',
          count: 108,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 6754,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
      name: 'Бытовая техника',
      path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F15%2F06%2F21%2F0ec2af6c-0768-4565-8ace-6916db612e3a%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
      metaDescription:
        'Бытовые товары для вашего дома – Баку, Азербайджан  –  Tap.Az',
      count: 5932,
      propertyOptions: [
        {
          name: 'Аспираторы ',
          legacyResourceId: '7657',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Блендеры, миксеры, кухонные комбайны',
          legacyResourceId: '7629',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Блинницы и вафельницы',
          legacyResourceId: '34686',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Электрочайники и термопоты',
          legacyResourceId: '7628',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Электрические самовары',
          legacyResourceId: '7687',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Мясорубки',
          legacyResourceId: '7632',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Фритюрницы',
          legacyResourceId: '34671',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Увлажнители и очистители воздуха',
          legacyResourceId: '38103',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Кондиционеры',
          legacyResourceId: '3895',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Микроволновые печи',
          legacyResourceId: '3891',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Мини-печи',
          legacyResourceId: '34629',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Мультиварки',
          legacyResourceId: '34728',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Стиральные машины',
          legacyResourceId: '3889',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Плиты',
          legacyResourceId: '3892',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Посудомоечные машины',
          legacyResourceId: '3893',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Кофеварки и кофемашины',
          legacyResourceId: '7633',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Обогреватели',
          legacyResourceId: '3896',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Духовые шкафы',
          legacyResourceId: '34628',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Самовары',
          legacyResourceId: '7686',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Вентиляторы',
          legacyResourceId: '34725',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Соковыжималки',
          legacyResourceId: '7634',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Холодильники',
          legacyResourceId: '3894',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Диспенсеры и кулеры для воды',
          legacyResourceId: '34627',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Фильтры и помпы для воды',
          legacyResourceId: '34621',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Сепараторы для молока',
          legacyResourceId: '34727',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Термометры',
          legacyResourceId: '34669',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Весы',
          legacyResourceId: '34685',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Швейные машины',
          legacyResourceId: '7631',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Тостеры и хлебопечи',
          legacyResourceId: '7630',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Пылесосы',
          legacyResourceId: '3888',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Утюги',
          legacyResourceId: '3890',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Вакуумные упаковщики',
          legacyResourceId: '34684',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
        {
          name: 'Другие',
          legacyResourceId: '3898',
          parentLegacyId: null,
          propertyLegacyId: '756',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
        name: 'Для дома и дачи',
        count: 26196,
        path: 'elanlar/ev-ve-bag-ucun',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
        __typename: 'Category',
      },
      children: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
      name: 'Работа',
      path: 'elanlar/is-elanlari',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
      __typename: 'Category',
      metaDescription: 'Вакансии, ищу работу',
      count: 5382,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OA',
          name: 'Вакансии',
          path: 'elanlar/is-elanlari/vakansiyalar',
          __typename: 'Category',
          count: 3064,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'Работа',
            count: 5382,
            path: 'elanlar/is-elanlari',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OQ',
          name: 'Ищу работу',
          path: 'elanlar/is-elanlari/is-axtariram',
          __typename: 'Category',
          count: 2318,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'Работа',
            count: 5382,
            path: 'elanlar/is-elanlari',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
      name: 'Животные',
      path: 'elanlar/heyvanlar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
      __typename: 'Category',
      metaDescription:
        'Собаки, кошки, птицы, аквариум и рыбы, другие животные, товары для животных',
      count: 5379,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NA',
          name: 'Собаки',
          path: 'elanlar/heyvanlar/itler',
          __typename: 'Category',
          count: 1210,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NQ',
          name: 'Кошки',
          path: 'elanlar/heyvanlar/pisikler',
          __typename: 'Category',
          count: 747,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Ng',
          name: 'Птицы',
          path: 'elanlar/heyvanlar/quslar',
          __typename: 'Category',
          count: 1606,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Nw',
          name: 'Аквариумы и рыбы',
          path: 'elanlar/heyvanlar/baliqlar-akvariumlar',
          __typename: 'Category',
          count: 471,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OQ',
          name: 'С/Х животные',
          path: 'elanlar/heyvanlar/kt-heyvanlari',
          __typename: 'Category',
          count: 480,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OA',
          name: 'Другие животные',
          path: 'elanlar/heyvanlar/diger-heyvanlar',
          __typename: 'Category',
          count: 9,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMA',
          name: 'Товары для животных',
          path: 'elanlar/heyvanlar/heyvanlar-ucun-mehsullar',
          __typename: 'Category',
          count: 684,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMA',
          name: 'Кролики',
          path: 'elanlar/heyvanlar/dovsanlar',
          __typename: 'Category',
          count: 84,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMQ',
          name: 'Лошади',
          path: 'elanlar/heyvanlar/atlar',
          __typename: 'Category',
          count: 47,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMg',
          name: 'Грызуны',
          path: 'elanlar/heyvanlar/gemiriciler',
          __typename: 'Category',
          count: 41,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 5379,
            path: 'elanlar/heyvanlar',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
      name: 'Детский мир',
      path: 'elanlar/usaqlar-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
      __typename: 'Category',
      metaDescription:
        'Автокресла, игрушки, детские коляски и автомобили, детская одежда, мебель, питание, для школьников и другое',
      count: 5219,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 172538,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OQ',
          name: 'Автокресла',
          path: 'elanlar/usaqlar-ucun/avtomobil-oturacaqlari',
          __typename: 'Category',
          count: 112,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MA',
          name: 'Игрушки',
          path: 'elanlar/usaqlar-ucun/oyuncaqlar',
          __typename: 'Category',
          count: 1122,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MQ',
          name: 'Детские коляски',
          path: 'elanlar/usaqlar-ucun/usaq-arabalari',
          __typename: 'Category',
          count: 730,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Nw',
          name: 'Детские автомобили',
          path: 'elanlar/usaqlar-ucun/usaq-avtomobilleri',
          __typename: 'Category',
          count: 207,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mw',
          name: 'Кроватки и колыбели',
          path: 'elanlar/usaqlar-ucun/usaq-carpayilari-ve-beshikler',
          __typename: 'Category',
          count: 979,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Ng',
          name: 'Детские переноски',
          path: 'elanlar/usaqlar-ucun/usaq-dasiyicilari',
          __typename: 'Category',
          count: 79,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mg',
          name: 'Детская одежда',
          path: 'elanlar/usaqlar-ucun/usaq-geyimleri',
          __typename: 'Category',
          count: 660,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mw',
          name: 'Детская мебель',
          path: 'elanlar/usaqlar-ucun/mebel',
          __typename: 'Category',
          count: 266,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NA',
          name: 'Питание и кормление',
          path: 'elanlar/usaqlar-ucun/usaq-yemekleri',
          __typename: 'Category',
          count: 99,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NQ',
          name: 'Горки и площадки',
          path: 'elanlar/usaqlar-ucun/usaq-suruskenleri-ve-oyun-meydancalari',
          __typename: 'Category',
          count: 43,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mg',
          name: 'Манежи',
          path: 'elanlar/usaqlar-ucun/manejler',
          __typename: 'Category',
          count: 42,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OQ',
          name: 'Для школьников',
          path: 'elanlar/usaqlar-ucun/mekteb-levazimatlari',
          __typename: 'Category',
          count: 474,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MA',
          name: 'Xодунки',
          path: 'elanlar/usaqlar-ucun/yurutecler',
          __typename: 'Category',
          count: 107,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOA',
          name: 'Купание и гигиена',
          path: 'elanlar/usaqlar-ucun/usaq-gigiyenasi',
          __typename: 'Category',
          count: 47,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOQ',
          name: 'Детский текстиль',
          path: 'elanlar/usaqlar-ucun/usaq-tekstili',
          __typename: 'Category',
          count: 31,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NA',
          name: 'Сиденья для кормления',
          path: 'elanlar/usaqlar-ucun/qidalanma-ucun-usaq-oturacaqlar',
          __typename: 'Category',
          count: 198,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NQ',
          name: 'Другое',
          path: 'elanlar/usaqlar-ucun/her-sey',
          __typename: 'Category',
          count: 23,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5219,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
            __typename: 'Category',
          },
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
      name: 'Ремонт и строительство',
      path: 'elanlar/xidmetler/temir-tikinti',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2025%2F02%2F10%2F16%2F59%2F18%2Ff1006921-b3d7-4153-a336-d3f68ade685f%2F1957_PTAk9XsjQ8he6mTnwxv8JQ.png',
      __typename: 'Category',
      metaDescription: 'Широкий выбор строительных услуг на сайте Tap.Az.',
      count: 1008,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
        name: 'Услуги и бизнес',
        count: 11261,
        path: 'elanlar/xidmetler',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
        __typename: 'Category',
      },
      children: [],
    },
  ],
};

const rootCategoriesMockAz = {
  categories: [
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
      name: 'Elektronika',
      path: 'elanlar/elektronika',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
      name: 'Nəqliyyat',
      path: 'elanlar/neqliyyat',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
      name: 'Daşınmaz əmlak',
      path: 'elanlar/dasinmaz-emlak',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
      name: 'Ev və bağ üçün',
      path: 'elanlar/ev-ve-bag-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
      name: 'Ehtiyat hissələri və aksesuarlar',
      path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F07%2F16%2F15%2F51%2F52%2F7df05b75-36e0-4ef8-a7c3-fa0b785ae9c1%2F12183_ogtQftp0VNM0b8HrfrDIcQ.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
      name: 'Telefonlar',
      path: 'elanlar/elektronika/telefonlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F17%2F04%2F59%2F8fecaa9e-ac94-4edc-9a70-ddc6239b6e02%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
      name: 'Xidmətlər və biznes',
      path: 'elanlar/xidmetler',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
      name: 'Şəxsi əşyalar',
      path: 'elanlar/sexsi-esyalar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
      name: 'Hobbi və asudə',
      path: 'elanlar/hobbi-ve-asude',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
      name: 'Məişət texnikası',
      path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F15%2F06%2F21%2F0ec2af6c-0768-4565-8ace-6916db612e3a%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
      name: 'İş elanları',
      path: 'elanlar/is-elanlari',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
      name: 'Heyvanlar',
      path: 'elanlar/heyvanlar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
      name: 'Uşaq aləmi',
      path: 'elanlar/usaqlar-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
      name: 'Təmir və tikinti',
      path: 'elanlar/xidmetler/temir-tikinti',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2025%2F02%2F10%2F16%2F59%2F18%2Ff1006921-b3d7-4153-a336-d3f68ade685f%2F1957_PTAk9XsjQ8he6mTnwxv8JQ.png',
      __typename: 'Category',
    },
  ],
};

export const rootCategoriesMockRu = {
  categories: [
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
      name: 'Электроника',
      path: 'elanlar/elektronika',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-20462167b14b85cb156e03c44ad6df27b4771847099c28119bfa85e8288a400e.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
      name: 'Транспорт',
      path: 'elanlar/neqliyyat',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-a22b4ee9344848b76ba1329b43367be937c7839817ad5e8bbe26ee7a5c55ba4f.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
      name: 'Недвижимость',
      path: 'elanlar/dasinmaz-emlak',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-7c645372591496070816f689b9d4898154393fa0119a41acc0d4482c6036ae14.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
      name: 'Для дома и дачи',
      path: 'elanlar/ev-ve-bag-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-bb03b23b13b2cdf1301f6914501c2a6d180baf510b7e9d28aa5f32c22c88cfee.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
      name: 'Автозапчасти и аксессуары',
      path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F07%2F16%2F15%2F51%2F52%2F7df05b75-36e0-4ef8-a7c3-fa0b785ae9c1%2F12183_ogtQftp0VNM0b8HrfrDIcQ.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
      name: 'Мобильные телефоны',
      path: 'elanlar/elektronika/telefonlar',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F17%2F04%2F59%2F8fecaa9e-ac94-4edc-9a70-ddc6239b6e02%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
      name: 'Услуги и бизнес',
      path: 'elanlar/xidmetler',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-abd07952c66587dff54eee91d0d8668b919fc923249882ff53bae02578ef2cb4.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
      name: 'Личные вещи',
      path: 'elanlar/sexsi-esyalar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-baa3b91011c733a6e89fb1a25c788fc9bc123999a810a2b14fc3a4dc7a51626a.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
      name: 'Хобби и отдых',
      path: 'elanlar/hobbi-ve-asude',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-9af0e05040b6294be441836a80bdae173b2a0e5b4f7058d50e55cb39ebb8a554.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
      name: 'Бытовая техника',
      path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2024%2F12%2F05%2F15%2F06%2F21%2F0ec2af6c-0768-4565-8ace-6916db612e3a%2F67900_VsfyEqu9G0cxtdUTwCN1tQ.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
      name: 'Работа',
      path: 'elanlar/is-elanlari',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-846e5b1fbbce52f9fa1bf425eb22e7f73944e2e2d5e90577144c78702303f7a4.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
      name: 'Животные',
      path: 'elanlar/heyvanlar',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-6fc2cb8eca9132da850bbde5ff3f414d5c55d79eb466ab4465fbcb5a7ea4afd7.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
      name: 'Детский мир',
      path: 'elanlar/usaqlar-ucun',
      icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-b3b90bb08c6ce1ea30bcf85429a82c37061554d345de949024df47449637d0e8.png',
      __typename: 'Category',
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
      name: 'Ремонт и строительство',
      path: 'elanlar/xidmetler/temir-tikinti',
      icon: 'https://tap.azstatic.com/uploads/f200x200/2025%2F02%2F10%2F16%2F59%2F18%2Ff1006921-b3d7-4153-a336-d3f68ade685f%2F1957_PTAk9XsjQ8he6mTnwxv8JQ.png',
      __typename: 'Category',
    },
  ],
};

export const categoriesMock = (locale: string) =>
  locale === 'ru' ? categoriesMockRu : categoriesMockAz;

export const rootCategoriesMock = (locale: string) =>
  locale === 'ru' ? rootCategoriesMockRu : rootCategoriesMockAz;

import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';

export const FieldTitle = styled(Typography.Title).attrs({ $variant: '3' })`
  color: ${({ theme }) => theme.palette.secondary.labels};
  padding: 15px 0 0;
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const PriceFieldsBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TwoFieldsInRow = styled.form`
  display: flex;
  gap: 15px;
`;

export const OptionButtonsBox = styled.div<{
  $hasTopGutter?: boolean;
  $hasBottomGutter?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  padding: 15px;

  ${({ $hasTopGutter }) =>
    $hasTopGutter &&
    css`
      margin-top: 15px;
    `}

  ${({ $hasBottomGutter }) =>
    $hasBottomGutter &&
    css`
      margin-bottom: 70px;
    `}
`;

export const OptionButton = styled(Typography.Body)<{
  $isActive: boolean;
  $isLowercase?: boolean;
}>`
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  border: 1px solid ${({ theme }) => theme.palette.primary.border};
  white-space: nowrap;
  overflow: hidden;
  text-transform: ${({ $isLowercase }) =>
    $isLowercase ? 'lowercase' : 'none'};

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.palette.brand.main};
      background-color: ${({ theme }) => theme.palette.tertiary.background};
      border: 1px solid ${({ theme }) => theme.palette.brand.main};
    `}
`;

export const FiltersDrawerCTA = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-top: auto;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.palette.secondary.background};
  z-index: 3;
`;

export const PropertyName = styled(Typography.Caption).attrs(() => ({
  $variant: '1',
}))<{ $hasTopGutter?: boolean }>`
  color: ${({ theme }) => theme.palette.secondary.labels};
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 0 15px;

  ${({ $hasTopGutter }) =>
    $hasTopGutter &&
    css`
      margin-top: 10px;
    `}
`;

import { create } from 'zustand';

type HandlerFunction = (() => void) | undefined;

interface SaveAdsHandlersStore {
  handleSaveLastAds: HandlerFunction;
  setSaveLastAdsHandler: (value: HandlerFunction) => void;
}

export const useSaveAdsHandlersStore = create<SaveAdsHandlersStore>(set => ({
  handleSaveLastAds: undefined,
  setSaveLastAdsHandler: value => set({ handleSaveLastAds: value }),
}));

import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { TopActions } from '@/components/TopActions/TopActions';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { CatalogueScrollGrid } from '@/components/CatalogueScrollGrid/CatalogueScrollGrid';
import { useScrollVisibility } from '@/hooks/useScrollVisibility';
import { Header } from '@/components/Header/Header';
import { SubcategoriesScrollGrid } from '@/components/SubcategoriesScrollGrid/SubcategoriesScrollGrid';
import { usePageTopControlsStore } from '@/store/usePageTopControlsStore';
import { PageTopControlsProps } from '@/components/PageTopControls/PageTopControls.types';
import { useBridgeState } from '@/bridge/hooks';
import { TOP_BANNER_HEIGHT } from '@/components/Banners/Banners.constants';
import { ANDROID_BANNER_HEIGHT } from '@/components/AndroidAppBanner/AndroidAppBanner.constants';
import { useIsTopBannerVisible } from '@/hooks/useIsTopBannerVisible';
import * as S from '@/components/PageTopControls/PageTopControls.styles';
import { useSearchStore } from '@/store/useSearchStore';
import { useAndroidBannersStore } from '@/store/useBannerStore/useAndroidBannerStore';
import { useNavigation } from '@/hooks/useNavigation';

export const PageTopControls: React.FC<
  PropsWithChildren<PageTopControlsProps>
> = ({
  children,
  hasBurger,
  hasSearch,
  hasCategories,
  headerTitle,
  hasSubcategories,
  isMainHeader,
  headerLeftAction,
  hasLogoLink,
  onInitialRender,
  hasFilters,
  hasAddNew,
  hasDivider,
  hasBorderBottom,
  customHeader,
  isBackRequired,
  hasLeadingAction,
  hasWhiteBg,
  headerNotificationContainer,
  leadingIcon,
  searchDataStat,
}) => {
  const { canGoBack, goBack } = useNavigation();
  const { isVisible, scrollPos, isTopOfPage } = useScrollVisibility();
  const controlsRef = useRef<HTMLDivElement>(null);
  const isTopBannerVisible = useIsTopBannerVisible();

  const [isAndroidBannerVisible] = useAndroidBannersStore(state => [
    state.isAndroidBannerVisible,
  ]);
  const [isSuggestionsOpened] = useSearchStore(state => [
    state.isSuggestionsOpened,
  ]);
  const [
    topControlsHeight,
    setTopControlsHeight,
    canBeFixed,
    topControlsPosition,
    setTopControlsPosition,
  ] = usePageTopControlsStore(state => [
    state.topControlsHeight,
    state.setTopControlsHeight,
    state.canBeFixed,
    state.topControlsPosition,
    state.setTopControlsPosition,
  ]);

  const { isWebView } = useBridgeState();

  useEffect(() => {
    if (controlsRef.current) {
      setTopControlsHeight(controlsRef.current.offsetHeight);
      onInitialRender?.(controlsRef.current.offsetHeight);
    }
  }, [isAndroidBannerVisible, scrollPos]);

  useEffect(() => {
    if (!isWebView) {
      const topControlsHeightFinal = isTopBannerVisible
        ? topControlsHeight + TOP_BANNER_HEIGHT
        : topControlsHeight;

      const topControlsHeightWithAndroid = isAndroidBannerVisible
        ? topControlsHeightFinal + ANDROID_BANNER_HEIGHT
        : topControlsHeightFinal;

      if (scrollPos >= topControlsHeightWithAndroid && canBeFixed) {
        setTopControlsPosition('fixed');
      }

      if (isTopOfPage) {
        setTopControlsPosition('relative');
      }
    }
  }, [isWebView, scrollPos, topControlsHeight, canBeFixed]);

  return (
    <>
      {topControlsPosition === 'fixed' && (
        <S.HomeViewTopControlsGutter $topControlsHeight={topControlsHeight} />
      )}
      <S.HomeViewTopControls
        ref={controlsRef}
        $isVisible={isVisible || isSuggestionsOpened}
        $position={topControlsPosition}
        $topControlsHeight={topControlsHeight}
        $hasBorderBottom={hasBorderBottom}
      >
        {isMainHeader ? (
          <TopActions />
        ) : (
          <Header
            title={headerTitle}
            hasBurger={hasBurger}
            leftAction={headerLeftAction}
            hasAddNew={hasAddNew}
            hasLogoLink={hasLogoLink}
            hasDivider={hasDivider}
            isBackRequired={isBackRequired}
            hasLeadingAction={hasLeadingAction}
            hasWhiteBg={hasWhiteBg}
            notificationContainer={headerNotificationContainer}
            leadingIcon={leadingIcon}
            canGoBack={canGoBack}
            goBack={goBack}
          >
            {customHeader}
          </Header>
        )}

        {hasSearch && (
          <SearchPanel hasFilters={hasFilters} dataStat={searchDataStat} />
        )}

        {hasCategories && <CatalogueScrollGrid />}

        {hasSubcategories && <SubcategoriesScrollGrid />}

        {children}
      </S.HomeViewTopControls>
    </>
  );
};

import styled from 'styled-components';

export const Form = styled.form`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  margin-bottom: 10px;
`;

export const CategoryWithCountText = styled.p`
  font-size: 16px;
  text-wrap: wrap;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const AdsCountLabel = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.secondary.labels};
`;

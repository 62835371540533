import React, { Fragment, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { CatalogueListItem } from '@/components/CatalogueListItem/CatalogueListItem';
import { getAdPagePath, sleep } from '@/utils/helpers';
import { Drawer } from '@/components/Drawer/Drawer';
import {
  DrawerItem,
  DrawerItems,
  DrawerItemsName,
} from '@/components/Drawer/Drawer.styles';
import {
  CategoryWithCountText,
  AdsCountLabel,
} from '@/components/Drawer/DrawerSearch.styles';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { ROUTES } from '@/constants/routes';
import {
  Category,
  GetCategoriesResponse,
  PropertyOption,
} from '@/api/types/categories.types';
import { Checkbox } from '@/components/UI/Checkbox/Checkbox';
import { findAndGroupCategoriesByParentName } from '@/components/CatalogueDrawer/CatalogueDrawer.helpers';
import { DRAWER_TRANSITION_TIME_MS } from '@/components/Drawer/Drawer.constants';
import { ALL_SHOPS_ID } from '@/components/CatalogueScrollGrid/CatalogueScrollGrid.constants';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { categoriesMock } from '@/__e2e__/mock/categoriesMock';
import { GET_CATEGORIES } from '@/api/queries/categories.queries';

interface CatalogueDrawerProps {
  title?: string;
  isOpen: boolean;
  close: () => void;
  onSelect?: (category: Category, subcategory?: Category | null) => void;
  onPropertySelect?: (
    option: PropertyOption,
    category: Category,
    path: string
  ) => void;
  hasShopsLink?: boolean;
  hasSubcategories?: boolean;
  hasSwitchCheckbox?: boolean;
  isInPortal?: boolean;
  searchPlaceholder?: string;
}

export interface CatalogueDrawerRef {
  reset: () => void;
}

export const CatalogueDrawer = React.forwardRef<
  CatalogueDrawerRef,
  CatalogueDrawerProps
>(
  (
    {
      title,
      isOpen,
      close,
      onSelect,
      onPropertySelect,
      hasShopsLink = true,
      hasSubcategories = true,
      hasSwitchCheckbox = true,
      isInPortal = true,
      searchPlaceholder,
    },
    catalogueDrawerRef
  ) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    const router = useRouter();
    const customPush = useCustomPush();

    const { data: { categories = [] } = {}, loading } =
      useQuery<GetCategoriesResponse>(GET_CATEGORIES, { skip: !isOpen });

    const finalCategories = (
      loading ? categoriesMock(language).categories : categories
    ) as Category[];

    const [isSubcategoryOpen, setIsSubcategoryOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<null | Category>(
      null
    );
    const [subcategoryId, setSubcategoryId] = useState('');
    const [query, setQuery] = useState('');

    const filteredSubcategories = useMemo(
      () =>
        Array.from(findAndGroupCategoriesByParentName(query, finalCategories)),
      [query]
    );

    useImperativeHandle(
      catalogueDrawerRef,
      () => ({
        reset: () => {
          setSubcategoryId('');
        },
      }),
      []
    );

    function openSubDrawer(category: Category) {
      setSelectedCategory(category);
      setIsSubcategoryOpen(true);
    }

    async function closeSubDrawer() {
      setIsSubcategoryOpen(false);
      await sleep(500);
      setSelectedCategory(null);
    }

    async function onCategoryClick(category: Category) {
      if (hasSubcategories) {
        openSubDrawer(category);
      } else {
        onSelect?.(category);
        close();
        await closeSubDrawer();
      }
    }

    async function onAllAdsClick() {
      if (!selectedCategory) return;

      close();
      await closeSubDrawer();

      if (onSelect) {
        setSubcategoryId(`${selectedCategory.path}-all`);
        onSelect(selectedCategory, null);
        return;
      }

      customPush(`/${selectedCategory.path}`);
    }

    async function onSubcategoryClick(
      subcategory: Category | null,
      parentCategory?: Category | null
    ) {
      const actualParentCategory = parentCategory || selectedCategory;

      if (!actualParentCategory || !onSelect) return;

      onSelect(actualParentCategory, subcategory);

      close();
      setSubcategoryId(subcategory?.id ?? '');
      await closeSubDrawer();
      await sleep(DRAWER_TRANSITION_TIME_MS);
      setQuery('');
    }

    function isSelectedCategoryPath(path?: string) {
      const currentPath = getAdPagePath(router.query.slug);
      const itemPath = path?.startsWith('/') ? path : `/${path}`;

      return itemPath === currentPath;
    }

    function onSelectSubcategory(subcategory: Category) {
      if (onSelect) {
        onSubcategoryClick(subcategory, subcategory.parent);
        return;
      }
      customPush(`/${subcategory.path}`);
    }

    function isSelectedSubcategory(subcategory: Category) {
      return subcategoryId
        ? subcategory.id === subcategoryId
        : isSelectedCategoryPath(subcategory.path);
    }

    const isAllAdsSelected = subcategoryId
      ? subcategoryId === `${selectedCategory?.path}-all`
      : isSelectedCategoryPath(selectedCategory?.path);

    async function onPropertyCheckboxSelect(
      item: PropertyOption,
      path: string
    ) {
      close();
      await closeSubDrawer();

      if (onPropertySelect && selectedCategory) {
        onPropertySelect(item, selectedCategory, path);
      } else {
        customPush(path);
      }
    }

    function renderCommonCheckbox(
      item: Category | PropertyOption,
      path: string
    ) {
      const key = 'id' in item ? item.id : item.legacyResourceId;
      return (
        <Checkbox
          onChangeHook={() =>
            onPropertyCheckboxSelect(item as PropertyOption, path)
          }
          key={key}
          label={item.name}
          appearance="radio"
          size="sm"
          hasSwitch={hasSwitchCheckbox}
        />
      );
    }

    function renderCategoryCheckbox(item: Category) {
      const path = `/${item.path}`;
      return onSelect ? (
        <Checkbox
          onChangeHook={() => onSubcategoryClick(item)}
          key={item.id}
          value={isSelectedSubcategory(item)}
          label={item.name}
          appearance="radio"
          size="sm"
          hasSwitch={hasSwitchCheckbox}
        />
      ) : (
        renderCommonCheckbox(item, path)
      );
    }

    function renderPropertyCheckbox(item: PropertyOption) {
      const path = `/${selectedCategory?.path}?p%5B${item.propertyLegacyId}%5D=${item.legacyResourceId}`;
      return renderCommonCheckbox(item, path);
    }

    return (
      <Drawer
        dataTestId="catalogue-drawer"
        isOpen={isOpen}
        close={close}
        title={title ?? t('catalogue.catalogue')}
        onSearch={onSelect && setQuery}
        isInPortal={isInPortal}
        searchPlaceholder={searchPlaceholder}
        isLoading={loading}
      >
        {query ? (
          filteredSubcategories.map(([parentName, subcategories]) => {
            return (
              <Fragment key={parentName}>
                <DrawerItemsName>{parentName}</DrawerItemsName>
                <DrawerItems>
                  {subcategories.map(subcategory => (
                    <Checkbox
                      onChangeHook={() => onSelectSubcategory(subcategory)}
                      key={subcategory.id}
                      value={isSelectedSubcategory(subcategory)}
                      label={
                        <CategoryWithCountText>
                          {subcategory.name}
                          {subcategory.count ? (
                            <AdsCountLabel>({subcategory.count})</AdsCountLabel>
                          ) : null}
                        </CategoryWithCountText>
                      }
                      appearance="radio"
                      size="sm"
                      hasSwitch={hasSwitchCheckbox}
                    />
                  ))}
                </DrawerItems>
              </Fragment>
            );
          })
        ) : (
          <>
            <DrawerItems>
              {finalCategories?.map((item, i) => {
                return (
                  <DrawerItem
                    key={item.id}
                    as="div"
                    $isFirst={!i}
                    onClick={() => onCategoryClick(item)}
                  >
                    <CatalogueListItem item={item} isLoading={loading} />
                  </DrawerItem>
                );
              })}

              {hasShopsLink && (
                <DrawerItem as="div">
                  <CatalogueListItem
                    data-stat="catalog-category-shop"
                    item={
                      {
                        id: ALL_SHOPS_ID,
                        icon: 'shop.png',
                        name: t('catalogue.stores'),
                        path: ROUTES.shops,
                      } as Category
                    }
                    isLoading={loading}
                  />
                </DrawerItem>
              )}
            </DrawerItems>

            <Drawer
              dataTestId="catalogue-subdrawer"
              isOpen={isSubcategoryOpen}
              title={selectedCategory?.name}
              left={{ icon: <AngleBackIcon />, onClick: closeSubDrawer }}
            >
              <DrawerItems>
                <Checkbox
                  onChangeHook={onAllAdsClick}
                  value={isAllAdsSelected}
                  label={t('catalogue.all_ads')}
                  appearance="radio"
                  size="sm"
                  hasSwitch={hasSwitchCheckbox}
                />

                {selectedCategory?.children.length
                  ? selectedCategory.children.map(renderCategoryCheckbox)
                  : selectedCategory?.propertyOptions?.map(
                      renderPropertyCheckbox
                    )}
              </DrawerItems>
            </Drawer>
          </>
        )}
      </Drawer>
    );
  }
);

CatalogueDrawer.displayName = 'CatalogueDrawer';

import { useRouter } from 'next/router';

export function useUrlAndHashFromPath(excludedParams: string[] = []) {
  const router = useRouter();
  const [url, hash] = router.asPath.split('#');
  const [clearUrl, queryString] = url.split('?');
  const queryParams = new URLSearchParams(queryString);

  excludedParams.forEach(param => queryParams.delete(param));

  return {
    url,
    hash,
    clearUrl,
    queryString,
    restQueryString: queryParams.toString(),
  };
}

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DrawerItems,
  DrawerItemsTitle,
} from '@/components/Drawer/Drawer.styles';
import { Drawer } from '@/components/Drawer/Drawer';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { useToggleOpenState } from '@/hooks/useToggleOpenState';
import { Property, PropertyOption } from '@/api/types/categories.types';
import { Collection } from '@/components/FiltersDrawer/FiltersDrawer.types';
import { CollectionSelectOption } from '@/components/FiltersDrawer/CollectionSelectOption';
import { SearchResultsPageTestIds } from '@/__e2e__/testIds/search-results-page';

type Props = {
  propertyLegacyId?: string;
  options?: PropertyOption[];
  collection?: Collection;
  setCollection?: React.Dispatch<React.SetStateAction<Collection>>;
  allProperties?: Property[];
  popularOptions?: PropertyOption[];
  onItemClick?: (option: PropertyOption) => void;
};

export type CollectionSelectDrawerRef = {
  toggle: () => void;
};

export const CollectionSelectDrawer = forwardRef<
  CollectionSelectDrawerRef,
  Props
>(
  (
    {
      propertyLegacyId,
      options,
      collection,
      allProperties,
      popularOptions,
      onItemClick,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isOpen, toggle] = useToggleOpenState();
    const [query, setQuery] = useState('');
    const name = allProperties?.find(el => el.id === propertyLegacyId)?.name;

    function onOptionClick(value: PropertyOption) {
      if (onItemClick) {
        onItemClick(value);
      }
    }

    useImperativeHandle(
      ref,
      () => {
        return { toggle };
      },
      []
    );
    const list = options?.filter(option =>
      option.name.toLowerCase().includes(query.toLowerCase())
    );

    const withPopularOptions = !!popularOptions?.length && !query;

    return (
      <Drawer
        isOpen={isOpen}
        title={name}
        left={{ icon: <AngleBackIcon />, onClick: toggle }}
        onSearch={setQuery}
        dataTestId={SearchResultsPageTestIds.collectionSelectDrawer}
      >
        {withPopularOptions && (
          <>
            <DrawerItemsTitle>{t('common.popular')}</DrawerItemsTitle>
            <DrawerItems>
              {popularOptions.map(option => {
                return (
                  <CollectionSelectOption
                    key={option.name}
                    option={option}
                    collection={collection}
                    onOptionClick={onOptionClick}
                  />
                );
              })}
            </DrawerItems>

            <DrawerItemsTitle $hasTopGutter>{t('common.all')}</DrawerItemsTitle>
          </>
        )}

        <DrawerItems>
          {list?.map(option => {
            return (
              <CollectionSelectOption
                key={option.name}
                option={option}
                collection={collection}
                onOptionClick={onOptionClick}
              />
            );
          })}
        </DrawerItems>
      </Drawer>
    );
  }
);

CollectionSelectDrawer.displayName = `CollectionSelectDrawer`;

import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { ButtonBox } from '@/components/UI/Button/Button.styles';
import { DRAWER_TRANSITION_TIME_MS } from '@/components/Drawer/Drawer.constants';
import { Button } from '@/components/UI/Button/Button';

export const DrawerBox = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  z-index: 10022;
  top: 0;
  right: 0;
  bottom: 0;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.background};
  transition: ${DRAWER_TRANSITION_TIME_MS}ms ease-in-out;
  padding-bottom: ${({ theme }) =>
    theme.isWebView ? 'env(safe-area-inset-bottom)' : 0};
`;

export const DrawerContentBox = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-bottom: 10px;
`;

export const DrawerChildren = styled.div<{ $isHeadFixed: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${({ $isHeadFixed }) =>
    $isHeadFixed &&
    css`
      overflow-y: auto;
    `}
`;

export const DrawerBottom = styled.div`
  padding: 10px 15px;
`;

export const DrawerSubtitle = styled(Typography.Body)`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  color: ${({ theme }) => theme.palette.secondary.labels};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  margin-bottom: 15px;
`;

export const DrawerItemsName = styled(Typography.Caption).attrs({
  $variant: '1',
})`
  padding: 20px 15px 10px;

  &:first-of-type {
    padding-top: 5px;
  }
`;

export const DrawerItems = styled.div<{
  $isInTheMiddle?: boolean;
  $isLast?: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.primary.background};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  padding: 0 15px;

  ${({ $isInTheMiddle }) =>
    $isInTheMiddle &&
    css`
      margin: 10px 0;
    `}

  ${({ $isLast }) =>
    $isLast &&
    css`
      margin-top: 10px;
    `}
    
    &:empty {
    padding: 0;
    border: none;
    margin: 0;
  }
`;

export const DrawerItemsTitle = styled(Typography.Caption).attrs({
  $variant: '1',
})<{ $hasTopGutter?: boolean }>`
  color: ${({ theme }) => theme.palette.secondary.labels};
  padding: ${({ $hasTopGutter }) =>
    $hasTopGutter ? '20px 15px 10px' : '10px 15px'};
  text-transform: uppercase;
`;

export const DrawerItem = styled(Typography.Body)<{
  $isHead?: boolean;
  $isFirst?: boolean;
  $isLast?: boolean;
  $hasBottomMargin?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  color: ${({ theme }) => theme.palette.primary.labels};
  background-color: ${({ theme }) => theme.palette.primary.background};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
  margin-bottom: ${({ $hasBottomMargin }) => ($hasBottomMargin ? '10px' : 0)};

  ${({ $isHead }) =>
    $isHead
      ? css`
          justify-content: center;
          min-height: 60px;
        `
      : css`
          margin: 0 auto;
        `}

  ${({ $isFirst }) =>
    $isFirst &&
    css`
      border-top: 0;
    `}
  
  ${({ $isLast }) =>
    $isLast &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
    `}
`;

export const DrawerClose = styled(ButtonBox).attrs(() => ({ type: 'button' }))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
`;

export const DrawerSimpleTitle = styled(Typography.Header).attrs(() => ({
  $variant: '3',
}))<{
  $capitalizeTitle?: boolean;
}>`
  ${({ $capitalizeTitle }) =>
    $capitalizeTitle &&
    css`
      text-transform: capitalize;
    `}
`;

export const DrawerTitle = styled(DrawerSimpleTitle)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const RightAction = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

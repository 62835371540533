import { useQuery } from '@apollo/client';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { useUrlAndHashFromPath } from '@/hooks/useUrlAndHashFromPath';
import { ROUTES } from '@/constants/routes';
import { QUERY } from '@/constants/common';
import { Properties } from '@/api/types/searchSuggestions.types';
import { KeywordsSource } from '@/components/SearchSuggestions/SearchSuggestions.types';
import { CHECK_AD_EXISTENCE } from '@/api/queries/ads.queries';
import { isOnlyNumbers } from '@/utils/helpers';
import { CheckAdExistenceResponse } from '@/api/types/ads.types';

interface SearchParams {
  categoryPath?: string;
  properties?: Properties;
  keywordsSource: KeywordsSource;
}

export function useSearch() {
  const customPush = useCustomPush();
  const { clearUrl, restQueryString } = useUrlAndHashFromPath([
    `${QUERY.q}[${QUERY.keywords}]`,
    QUERY.button,
    QUERY.mobile_site,
    QUERY.slug,
    QUERY.keywords_source,
    QUERY.user_id,
  ]);

  const { refetch: checkAdExistence } = useQuery<CheckAdExistenceResponse>(
    CHECK_AD_EXISTENCE,
    {
      skip: true,
      errorPolicy: 'all',
    }
  );

  async function search(searchQuery: string, params?: SearchParams) {
    if (isOnlyNumbers(searchQuery)) {
      const { data } = await checkAdExistence({
        legacyId: Number(searchQuery),
      });

      const adPath = data?.adExistence;

      if (adPath) {
        customPush(adPath);
        return;
      }
    }

    const queryParams = {
      [`${QUERY.q}[${QUERY.keywords}]`]: searchQuery,
      button: '',
      mobile_site: 'true',
      keywords_source: params?.keywordsSource ?? '',
    };

    const existingParams = new URLSearchParams(restQueryString);

    if (params?.keywordsSource === KeywordsSource.Suggestion) {
      Array.from(existingParams.keys()).forEach(key => {
        if (key.startsWith('p[')) {
          existingParams.delete(key);
        }
      });
    }

    if (params?.properties) {
      Object.entries(params.properties).forEach(([key, value]) => {
        queryParams[`p[${key}]`] = String(value);
      });
    }

    const queryParamsString = new URLSearchParams(queryParams).toString();
    const existingParamsString = existingParams.toString();

    const getPathname = () => {
      if (params?.categoryPath) return `/${params?.categoryPath}`;

      return clearUrl.includes(ROUTES.ad) ? clearUrl : ROUTES.ad;
    };

    const currentUrl = `${window.location.pathname}${window.location.search}`;
    const nextUrl = `${getPathname()}?${queryParamsString}${
      existingParamsString ? `&${existingParamsString}` : ''
    }`;

    if (currentUrl !== nextUrl) {
      customPush(nextUrl);
    }
  }

  return search;
}

import { useQuery } from '@apollo/client';
import { useCurrentCategory } from './useCurrentCategory/useCurrentCategory';
import { useParseQuery } from '@/hooks/useParseQuery/useParseQuery';
import { GetCategoryEntitiesResponse } from '@/api/types/categories.types';
import { GET_CATEGORY_ENTITIES } from '@/api/queries/categories.queries';

export function useCategoryEntities() {
  const { currentCategory, isAllCategories } = useCurrentCategory();
  const {
    keywords,
    categoryId,
    price,
    regionId,
    propertyOptions,
    userLegacyId,
  } = useParseQuery();

  const currentCategoryId = categoryId || currentCategory?.id;

  const {
    data: { categoryEntities = [] } = {},
    loading,
    called,
  } = useQuery<GetCategoryEntitiesResponse>(GET_CATEGORY_ENTITIES, {
    variables: {
      keywords,
      filters: {
        categoryId: currentCategoryId,
        price,
        regionId,
        propertyOptions,
        userLegacyId,
      },
    },
    skip: !currentCategoryId || isAllCategories,
  });

  return { categoryEntities, loading: called && loading };
}

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { KeywordsSource } from '@/components/SearchSuggestions/SearchSuggestions.types';
import { Drawer } from '@/components/Drawer/Drawer';
import { DrawerItems } from '@/components/Drawer/Drawer.styles';
import { Select } from '@/components/UI/Select/Select';
import { Button } from '@/components/UI/Button/Button';
import { Input } from '@/components/UI/Input/Input';
import {
  CatalogueDrawer,
  CatalogueDrawerRef,
} from '@/components/CatalogueDrawer/CatalogueDrawer';
import { CitiesDrawer } from '@/components/CitiesDrawer/CitiesDrawer';
import {
  Category,
  PropertyOption,
  PropertyType,
} from '@/api/types/categories.types';
import { GetRegionsResponse, Region } from '@/api/types/regions.types';
import { AdOrder } from '@/api/types/ads.types';
import { FiltersDrawerSortItems } from '@/components/FiltersDrawer/FiltersDrawerSortItems';
import {
  replaceUrlPath,
  separateBooleanProperties,
  updateURLParameters,
} from '@/components/FiltersDrawer/FiltersDrawer.helpers';
import { useUrlAndHashFromPath } from '@/hooks/useUrlAndHashFromPath';
import { BooleanOptions } from '@/components/FiltersDrawer/BooleanOptions';
import * as S from '@/components/FiltersDrawer/FiltersDrawer.styles';
import {
  BooleanCollection,
  CatalogueDrawerProps,
  CategoryData,
  Collection,
  RangeCollection,
} from '@/components/FiltersDrawer/FiltersDrawer.types';
import { useParseQuery } from '@/hooks/useParseQuery/useParseQuery';
import { RangeOptions } from '@/components/FiltersDrawer/RangeOptions';
import { GET_REGIONS } from '@/api/queries/regions.queries';
import { CollectionSelect } from '@/components/FiltersDrawer/CollectionSelect';
import { formatNumber } from '@/utils/helpers';
import {
  mapBooleanPropertyOptionsToBooleanCollection,
  mapCollectionPropertyOptionsToCollection,
  mapRangePropertyOptionsToRangeCollection,
} from '@/hooks/useParseQuery/useParseQuery.helpers';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import { useCurrentCategory } from '@/hooks/useCurrentCategory/useCurrentCategory';
import { useCategoryProperties } from '@/hooks/useCategoryProperties';

export const FiltersDrawer = ({
  isOpen,
  close,
  setFilterCount,
}: CatalogueDrawerProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [prices, setPrices] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: null, to: null });
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isCitiesOpen, setIsCitiesOpen] = useState(false);
  const [sort, setSort] = useState<AdOrder>(AdOrder.DateDesc);
  const [city, setCity] = useState<Region | null>(null);
  const [categoryData, setCategoryData] = useState<CategoryData | null>(null);
  const [booleanCollection, setBooleanCollection] = useState<BooleanCollection>(
    {}
  );
  const [rangeCollection, setRangeCollection] = useState<RangeCollection>({});
  const [collection, setCollection] = useState<Collection>({});
  const catalogueDrawerRef = useRef<CatalogueDrawerRef>(null);

  function clearAllCollections() {
    setCollection({});
    setBooleanCollection({});
    setRangeCollection({});
  }

  function resetForm() {
    setCategoryData(null);
    setPrices({ from: null, to: null });
    setCity(null);
    setSort(AdOrder.DateDesc);
    clearAllCollections();
    catalogueDrawerRef.current?.reset();
  }

  const {
    propertyOptions: {
      boolean: booleanPropertyOptions,
      range: rangePropertyOptions,
      collection: collectionPropertyOptions,
    },
    price: { from: priceFrom, to: priceTo },
    regionLegacyId,
    order,
  } = useParseQuery();

  const { data: regions } = useQuery<GetRegionsResponse>(GET_REGIONS);

  const { clearUrl } = useUrlAndHashFromPath();
  const { currentCategory, isAllCategories } = useCurrentCategory();
  const { categoryProperties, refetchCategoryProperties } =
    useCategoryProperties();
  const isFirstLevel = router.query.slug?.length === 1;
  const isSubcategories = router.query.slug?.length === 2;
  const { booleanProperties, restProperties } =
    separateBooleanProperties(categoryProperties);

  function toggleCategoriesSubDrawer() {
    setIsCategoriesOpen(prev => !prev);
  }

  function toggleCitiesSubDrawer() {
    setIsCitiesOpen(prev => !prev);
  }

  function onSelectCategory(category: Category, subcategory?: Category | null) {
    setCategoryData({
      category,
      subcategory,
      customPath: !subcategory ? category.path : undefined,
    });

    clearAllCollections();
    refetchCategoryProperties({ path: subcategory?.path || category?.path });
  }

  function onPropertySelect(
    option: PropertyOption,
    category: Category,
    path: string
  ) {
    const cleanPath = path.split('?')[0].substring(1);

    clearAllCollections();
    setCategoryData({ category, customPath: cleanPath });
    setCollection({ [option.propertyLegacyId]: option.legacyResourceId });
    refetchCategoryProperties({ path: cleanPath });
  }

  function applyFilters() {
    const path = categoryData?.subcategory?.path || categoryData?.customPath;
    const newParams = {
      categoryId: categoryData?.subcategory?.id ?? '',
      'q[price][]': [prices.from ?? '', prices.to ?? ''],
      'q[region_id]': city?.legacyResourceId ?? '',
      order: sort.toLowerCase(),
      keywords_source: KeywordsSource.Typewritten,
    };

    const newURL = updateURLParameters({
      url: window.location.href,
      params: newParams,
      booleanCollection,
      rangeCollection,
      collection,
      hasCategory: !!categoryData,
    });

    close();
    setTimeout(() => {
      if (!path || clearUrl === path) {
        router.push(newURL);
      } else if (path) {
        router.push(replaceUrlPath(newURL, path));
      }
    }, 0);
  }

  function changePrice(e: string, fieldName: keyof typeof prices) {
    setPrices({ ...prices, [fieldName]: e });
  }

  useEffect(() => {
    if (!currentCategory) return;

    if (isAllCategories) {
      setCategoryData(null);
    }

    if (isFirstLevel) {
      setCategoryData({ category: currentCategory, subcategory: undefined });
    }

    if (isSubcategories && currentCategory.parent) {
      setCategoryData({
        category: currentCategory.parent,
        subcategory: currentCategory,
      });
    }
    catalogueDrawerRef.current?.reset();
  }, [currentCategory, isOpen]);

  useEffect(() => {
    const booleanCollectionFromQuery =
      mapBooleanPropertyOptionsToBooleanCollection(booleanPropertyOptions);
    const rangeCollectionFromQuery =
      mapRangePropertyOptionsToRangeCollection(rangePropertyOptions);
    const collectionFromQuery = mapCollectionPropertyOptionsToCollection(
      collectionPropertyOptions
    );

    setBooleanCollection(booleanCollectionFromQuery);
    setRangeCollection(rangeCollectionFromQuery);
    setCollection(collectionFromQuery);
    setPrices({
      from: priceFrom ? formatNumber(priceFrom) : null,
      to: priceTo ? formatNumber(priceTo) : null,
    });
    setSort(order ?? AdOrder.DateDesc);
  }, [
    booleanPropertyOptions,
    rangePropertyOptions,
    collectionPropertyOptions,
    priceFrom,
    priceTo,
    order,
    isOpen,
  ]);

  useEffect(() => {
    if (regions) {
      setCity(
        regions.regions.find(
          r => Number(r.legacyResourceId) === Number(regionLegacyId)
        ) ?? null
      );
    }
  }, [regions, regionLegacyId, isOpen]);

  const filtersCount = useMemo(() => {
    let result = 0;

    const conditions = [
      !!categoryData,
      !!(prices.from || prices.to),
      !!city,
      sort && sort !== AdOrder.DateDesc,
      ...Object.values(collection),
      ...Object.values(booleanCollection).map(el => el !== undefined),
      ...Object.values(rangeCollection).map(
        val => !!(val && (val[0] || val[1]))
      ),
    ];

    result = conditions.reduce(
      (sum, condition) => sum + (condition ? 1 : 0),
      0
    );
    setFilterCount(result);
    return result;
  }, [
    categoryData,
    prices,
    city,
    sort,
    collection,
    booleanCollection,
    rangeCollection,
  ]);

  return (
    <Drawer
      isOpen={isOpen}
      close={close}
      title={t('common.filters')}
      right={{ onClick: resetForm, isDisabled: !filtersCount }}
      isInPortal
      dataTestId="filters-drawer"
    >
      <DrawerItems data-testid="category-select">
        <Select
          label={t('common.category')}
          toggle={toggleCategoriesSubDrawer}
          value={
            categoryData?.subcategory?.name ||
            categoryData?.category?.name ||
            ''
          }
          iconSrc={
            categoryData?.subcategory?.icon
              ? categoryData.subcategory.icon
              : categoryData?.category.icon
          }
          hasIcon
          onClear={resetForm}
        >
          <CatalogueDrawer
            title={t('catalogue.category')}
            isOpen={isCategoriesOpen}
            close={toggleCategoriesSubDrawer}
            onSelect={onSelectCategory}
            onPropertySelect={onPropertySelect}
            hasShopsLink={false}
            hasSubcategories
            isInPortal={false}
            ref={catalogueDrawerRef}
            searchPlaceholder={t('catalogue.category_search')}
          />
        </Select>
      </DrawerItems>

      <DrawerItems $isLast>
        <S.PriceContainer>
          <IconBox $filename="price.svg" width={17} height={17} />
          <S.PriceFieldsBox>
            <S.FieldTitle>{t('common.price_azn')}</S.FieldTitle>
            <S.TwoFieldsInRow>
              <Input
                value={prices.from?.toString() ?? ''}
                onChange={value => changePrice(value as string, 'from')}
                clear={() => setPrices({ ...prices, from: null })}
                type="text"
                inputMode="numeric"
                placeholder={t('common.from')}
                isNumberWithSpaces
                customPaddingTop={2}
                className="string required form-control min"
                data-testid="price-from"
              />
              <Input
                value={prices.to?.toString() ?? ''}
                onChange={value => changePrice(value as string, 'to')}
                clear={() => setPrices({ ...prices, to: null })}
                type="text"
                inputMode="numeric"
                placeholder={t('common.to')}
                isNumberWithSpaces
                customPaddingTop={2}
                className="string required form-control max"
                data-testid="price-to"
              />
            </S.TwoFieldsInRow>
          </S.PriceFieldsBox>
        </S.PriceContainer>

        <Select
          label={t('common.city')}
          toggle={toggleCitiesSubDrawer}
          value={city?.name ?? ''}
          onClear={() => setCity(null)}
          hasIcon
          icon={<IconBox $filename="location.svg" width={15} height={22} />}
        >
          <CitiesDrawer
            isOpen={isCitiesOpen}
            close={toggleCitiesSubDrawer}
            selected={city?.name ?? ''}
            onSelect={setCity}
          />
        </Select>
      </DrawerItems>

      {!!restProperties.length && categoryData && (
        <DrawerItems $isLast>
          {restProperties.map(p => {
            switch (p.type) {
              case PropertyType.CollectionProperty:
                return (
                  <CollectionSelect
                    key={p.id}
                    propertyLegacyId={p.id}
                    options={p.options}
                    popularOptions={p.popularOptions}
                    collection={collection}
                    setCollection={setCollection}
                    allProperties={categoryProperties}
                    isFiltersOpen={isOpen}
                  />
                );

              case PropertyType.RangeProperty:
                return (
                  <RangeOptions
                    key={p.id}
                    property={p}
                    collection={rangeCollection}
                    setCollection={setRangeCollection}
                  />
                );

              default:
                return null;
            }
          })}
        </DrawerItems>
      )}

      {categoryData &&
        booleanProperties.map(bp => {
          return (
            <BooleanOptions
              key={bp.id}
              property={bp}
              collection={booleanCollection}
              setCollection={setBooleanCollection}
            />
          );
        })}

      <FiltersDrawerSortItems sort={sort} setSort={setSort} />

      <S.FiltersDrawerCTA>
        <Button
          data-testid="apply-filters"
          appearance="primary"
          isFullWidth
          onClick={applyFilters}
        >
          {t('common.show')}
        </Button>
      </S.FiltersDrawerCTA>
    </Drawer>
  );
};

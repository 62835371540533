import { useBridgeState } from '@/bridge/hooks';
import { PlatformSource } from '@/api/types/common.types';

export function usePlatformSource() {
  const { isWebView } = useBridgeState();

  switch (true) {
    case isWebView:
      return PlatformSource.App;
    default:
      return PlatformSource.Mobile;
  }
}

import { useQuery } from '@apollo/client';
import {
  GetChildPropertyOptionsResponse,
  PropertyOption,
} from '@/api/types/categories.types';
import { useCategoryEntities } from '@/hooks/useCategoryEntities';
import { GET_CHILD_PROPERTY_OPTIONS } from '@/api/queries/categories.queries';

export function useAllListOptions() {
  const { categoryEntities } = useCategoryEntities();
  const { parentLegacyId, propertyLegacyId } = (categoryEntities[0] ??
    {}) as PropertyOption;

  const { data } = useQuery<GetChildPropertyOptionsResponse>(
    GET_CHILD_PROPERTY_OPTIONS,
    {
      variables: { legacyId: propertyLegacyId, parentLegacyId },
      skip: !parentLegacyId && !propertyLegacyId,
    }
  );

  return data?.property.options ?? [];
}

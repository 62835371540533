import { STORAGE_KEYS } from '@/constants/storageKeys';
import {
  HomePageAdsData,
  LastAdsData,
  LastScrollPositionData,
  SearchResultsPageAdsData,
  UserAdsPageAdsData,
} from '@/components/ScrollSaver/ScrollSaver.types';
import { PAGE_WITH_ADS } from '@/constants/common';

export function calculateBannersHeight() {
  let bannersHeight = 0;
  const topBanner = document.querySelector('.lotriver-top-banner');
  const bottomBanners = document.querySelectorAll('.lotriver-bottom-banner');
  bottomBanners.forEach(el => {
    if ((el as HTMLElement)?.offsetTop < window.scrollY) {
      bannersHeight += el.clientHeight;
    }
  });

  bannersHeight += topBanner?.clientHeight ?? 0;

  return bannersHeight;
}

export function handleSaveScrollPositionData(page: PAGE_WITH_ADS) {
  localStorage.setItem(
    STORAGE_KEYS.LAST_SCROLL_POSITION_DATA,
    JSON.stringify({
      page,
      lastScrollPosition: window.scrollY,
      lastBannersHeight: calculateBannersHeight(),
    })
  );
}

export function clearLastAdsData() {
  localStorage.removeItem(STORAGE_KEYS.LAST_ADS_DATA);
}

export function clearLastScrollPositionData() {
  localStorage.removeItem(STORAGE_KEYS.LAST_SCROLL_POSITION_DATA);
}

export function getSavedAdsData(
  page: PAGE_WITH_ADS.HOME
): HomePageAdsData | null;
export function getSavedAdsData(
  page: PAGE_WITH_ADS.SEARCH_RESULTS
): SearchResultsPageAdsData | null;
export function getSavedAdsData(
  page: PAGE_WITH_ADS.USER_ADS
): UserAdsPageAdsData | null;

export function getSavedAdsData(page: PAGE_WITH_ADS): LastAdsData | null {
  try {
    const data = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LAST_ADS_DATA) || '{}'
    ) as LastAdsData;

    return data.page === page ? data : null;
  } catch (e) {
    return null;
  }
}

export function clearSavedData() {
  clearLastAdsData();
  clearLastScrollPositionData();
}

export function getPreviousBannersHeight(page: PAGE_WITH_ADS) {
  const scrollPositionData = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.LAST_SCROLL_POSITION_DATA) || '{}'
  ) as LastScrollPositionData;

  return scrollPositionData.page === page
    ? scrollPositionData.lastBannersHeight
    : 0;
}

export function getLastScrollPosition(page: PAGE_WITH_ADS) {
  const scrollPositionData = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.LAST_SCROLL_POSITION_DATA) || '{}'
  ) as LastScrollPositionData;

  return scrollPositionData.page === page
    ? scrollPositionData.lastScrollPosition
    : 0;
}
